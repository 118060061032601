import { Typography } from '@mui/material';
import { CustomTabPanel } from '../CustomPanel';

interface Props {
  value: number;
}

const Loader = ({ value }: Props) => {
  return (
    <>
      <CustomTabPanel value={value} index={2}>
        <div>
          <Typography>Loaders Tab</Typography>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Loader;
