import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import { formatToUSD } from 'views/SurvivalAnalysis/Helper/Function/helper.function';

interface SumPieChartProps {
  title: string;
  label1: string;
  label2: string;
  number1: number;
  number2: number;
  totalType: string;
  enableDarkTheme: boolean;
  precision: number;
  color1: string; // Add color1 prop
  color2: string; // Add color2 prop
}

const KPIPieChart: React.FC<SumPieChartProps> = ({
  title,
  label1,
  label2,
  number1,
  number2,
  totalType,
  enableDarkTheme,
  precision,
  color1, // Use color1 prop
  color2, // Use color2 prop
}) => {
  const data = [
    { name: label1, value: number1, color: color1 }, // Use color1 for label1
    { name: label2, value: number2, color: color2 }, // Use color2 for label2
  ];

  const total = number1 + number2;

  return (
    <Card
      sx={{
        background: enableDarkTheme
          ? ThemePalette.dark.tableBackground
          : ThemePalette.light.tableBackground,
      }}
    >
      <CardContent>
        {/* Title */}
        <Typography
          variant="body2"
          fontSize={12}
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            marginBottom: '12px',
          }}
        >
          {title}
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center">
          {/* Pie Chart */}
          <ResponsiveContainer width={150} height={140}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={70}
                fill="#8884d8"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
                {/* Custom Label to Display Total and Total Type on Separate Lines */}
                <Label
                  position="center"
                  content={() => (
                    <text
                      x="50%"
                      y="50%"
                      fill={
                        enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black
                      }
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontSize={16}
                      fontWeight="bold"
                    >
                      <tspan x="50%" dy="-0.2em">
                        {formatToUSD(total, '')}
                      </tspan>
                      <tspan x="50%" dy="1.2em">
                        {totalType.toUpperCase()}
                      </tspan>
                    </text>
                  )}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          {/* Legend */}
          <Box display="flex" flexDirection="column" ml={2}>
            {data.map((entry, index) => (
              <Box
                key={`legend-${index}`}
                display="flex"
                alignItems="center"
                mb={1}
              >
                <Box
                  width={8}
                  height={8}
                  bgcolor={entry.color}
                  borderRadius="50%"
                  mr={1}
                />
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body1"
                  fontSize={12}
                >
                  {/* Entry Name */}
                  <Typography variant="body2" fontSize={12}>
                    {entry.name}
                  </Typography>

                  {/* Formatted Value */}
                  <Typography variant="body1" fontSize={12} fontWeight="bold">
                    {formatToUSD(entry.value, totalType)}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default KPIPieChart;
