import { createTheme, responsiveFontSizes, type Palette } from '@mui/material';

/*

NOTE  Heston is working on the design so we may change

Mui themes can be augmented with custom properties.

For example, if you want to add "sidebar" to the primary palette

interface Theme {
    palette: {
      primary: {
        sidebar: string;
      };
    };
  }

  interface ThemeOptions {
    palette?: {
      primary?: {
        sidebar?: string;
      };
    };
  }

*/

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SWPalette {
  bg: Palette['primary'] & {
    blue: string;
    grey: string;
    paper: string;
  };
  button: Palette['primary'] & {
    primary: string;
  };
  svgIcons: Partial<Palette['primary']> & {
    primary: {
      fill: string;
    };
    error: {
      fill: string;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SWPaletteOptions {
  bg?: Partial<Palette['primary']> & {
    blue?: string;
    grey?: string;
    paper?: string;
  };
  button?: Partial<Palette['primary']> & {
    primary?: string;
  };
  svgIcons: Partial<Palette['primary']> & {
    primary: {
      fill: string;
    };
    error: {
      fill: string;
    };
  };
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    large: React.CSSProperties;
    medium: React.CSSProperties;
    bodyBold: React.CSSProperties;
    small: React.CSSProperties;
  }

  // Augment the Palette
  interface Palette extends SWPalette {}
  interface PaletteOptions extends SWPaletteOptions {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    large?: React.CSSProperties;
    medium?: React.CSSProperties;
    bodyBold?: React.CSSProperties;
    small?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    large: true;
    medium: true;
    bodyBold: true;
    small: true;
  }
}

const primary = {
  main: '#022859',
  dark: '#021A39',
};

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary,
      bg: {
        blue: '#DFE5F0',
        grey: '#F3F4F6',
        paper: '#FFFFFF',
      },
      button: {
        primary: '#1049E5',
      },
      svgIcons: {
        primary: {
          fill: '#1049E5',
        },
        error: {
          fill: '#A03434',
        },
      },
    },
    typography: {
      fontFamily: ['Montserrat', 'Open Sans'].join(','),
      large: {
        fontSize: '16px',
      },
      medium: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      small: {
        fontSize: '12px',
        fontFamily: 'Open Sans',
      },
      bodyBold: {
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Open Sans',
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: 'white',
            backgroundColor: primary.main,
            fontSize: '14px',
            maxWidth: '175px',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          sx(theme) {
            return {
              fontFamily: 'Montserrat',
            };
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: '#749A4A',
            color: 'white',
          },
          standardError: {
            backgroundColor: '#A03434',
            color: 'white',
          },
        },
      },
    },
  })
);

export const ThemePalette = {
  light: {
    pageBackground: '#DFE5F0',
    topBar: '#FFFFFF',
    boxBackground: '#FFFFFF',
    toolBarBackground: '#F3F4F6',
    cartesianGrid: '#cccccc',
    menuOptions: '#FFFFFF',
  },
  dark: {
    pageBackground: '#0A192D',
    topBar: '#152642',
    boxBackground: '#172A46',
    toolBarBackground: '#152642',
    modalBackground: '#0A2341',
    cartesianGrid: '#26364C',
    menuOptions: '#091A2F',
  },
  typography: {
    buttonLink: '#488AA0',
    primary: '#2449E5',
    secondary: '#7A7C7C',
    success: '#28a745',
    lightSuccess: '#009161',
    danger: '#dc3545',
    warning: '#FFA403',
    lightDanger: '#EB5757',
    info: '#437F95',
    wheat: '#EFF4F4',
    white: '#FFFFFF',
    black: '#000000',
    cyan: '#7485A2',
    lightGrey: '#B4B4B4',
    grey: '#8A92A0',
    cornflowerBlue: '#6495ED',
    mediumGray: '#8A8A8A',
  },
  border: '#D9DADA',
};
