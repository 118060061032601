import { Typography } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { LinkButton } from 'components/Buttons/LinkButton';
import { FlexBox } from 'components/Containers/FlexBox';
import Permission from 'components/Permission/Permission';
import { navigationMap } from 'components/Route/navigationMap';
import { useNavigate } from 'react-router-dom';
import { NotificationCounter } from './Notification';
import { WeatherDisplay } from './Weather';
import { isDarkTheme } from 'utils/theme';
import { ThemeToggle } from './ThemeToggle';
import { ThemePalette } from 'mui.theme';

/*
  This component is used to display the current customer in the top bar.
  It is only visible to users with the director or internal-user role.
*/
export const CustomerDisplay = (customer: { name: string; id: string }) => {
  const navigateTo = useNavigate();
  const { data, isLoading } = useAppSelector((state) => state.weatherReducer);

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const customerCode = useAppSelector((state) => state.persistedReducer)
    .customer.code;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <FlexBox
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        float: 'right',
        marginRight: '20px',
      }}
    >
      {isLoading ? (
        <></>
      ) : (
        <WeatherDisplay
          data={data}
          enableDarkTheme={enableDarkTheme}
          customerCode={customerCode}
        />
      )}
      <NotificationCounter />
      <ThemeToggle enableDarkTheme={enableDarkTheme} />
      <Typography
        sx={{
          marginRight: '10px',
          marginLeft: '10px',
          color: enableDarkTheme
            ? ThemePalette.typography.grey
            : ThemePalette.typography.black,
        }}
        fontSize="14px"
      >
        {customer.name}
      </Typography>
      <Permission
        roles={['director', 'internal-user']}
        fallbackComponent={<div />}
      >
        <>
          <LinkButton
            onClick={() => {
              navigateTo(navigationMap.customerSelectionView.path, {
                replace: true,
              });
            }}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.buttonLink
                : ThemePalette.typography.primary,
            }}
          >
            Change
          </LinkButton>
        </>
      </Permission>
    </FlexBox>
  );
};
