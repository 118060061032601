import { Grid, Typography } from '@mui/material';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import TableList from 'views/Production/components/Table/TableList';
import { FlexBox } from 'components/Containers/FlexBox';
import type { AveragrCycleTimeType } from 'types/production.types';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  data: AveragrCycleTimeType[];
  isLoading: boolean;
}

const AverageCycleTime = ({
  enableDarkTheme,
  title,
  data,
  isLoading,
}: Props) => {
  const hasChartData = data.some((obj) => obj.value && obj.value !== 0);
  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    paddingTop: '20px',
                    minHeight: '200px',
                    maxHeight: '200px',
                  }}
                >
                  {hasChartData ? (
                    <FlexBox
                      sx={{
                        maxWidth: 'inherit',
                        height: '230px',
                        paddingTop: '20px',
                        marginRight: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            data={data}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </FlexBox>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: '11px',
                      }}
                    >
                      No data available currently
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} sm={12}>
                <TableList enableDarkTheme={enableDarkTheme} data={data} />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default AverageCycleTime;
