import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from '../base.handler';
import DashboardSquaresAPI from 'api/Squares/Dashboard/squares.api';

import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';
import {
  setHealthScore,
  setSquares,
  setSquaresAssetsDashboard,
  setSquaresDashboard,
} from 'store/SquaresStore/DashboardSquaresState.slice';
import { useAppSelector } from 'store/hook';

export default class SquaresDashboardHandler extends BaseHandler {
  private readonly apiDashboard: DashboardSquaresAPI;
  private readonly customer = useAppSelector((state) => state.persistedReducer)
    .customer.code;

  constructor() {
    super();
    this.apiDashboard = new DashboardSquaresAPI();
  }

  async getPmVsCmVsModification(
    selectedStartDate: Date,
    selectedEndDate: Date
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getPmVsCmVsModification(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getPmVsCmExpenditures(selectedStartDate: Date, selectedEndDate: Date) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getPmVsCmExpenditures(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getPartsTotalCost(selectedStartDate: Date, selectedEndDate: Date) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getPartsTotalCost(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getPartsTotalQuantity(selectedStartDate: Date, selectedEndDate: Date) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getPartsTotalQuantity(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getPartsTotalOrderHours(
    selectedStartDate: Date,
    selectedEndDate: Date
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getPartsTotalOrderHours(payload);

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getDashboardAssetsAscDesc(
    selectedStartDate: Date,
    selectedEndDate: Date,
    sort: string,
    orderBy: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        customerName: this.customer,
        selectedStartDate,
        selectedEndDate,
        sort,
        orderBy,
      };
      const squares = await this.apiDashboard.getDashboardAssetsAscDesc(
        payload
      );

      this.dispatch(setSquares(squares.Items));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getPartsComponentsData(selectedStartDate: Date, selectedEndDate: Date) {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getDashboardComponentswData(
        payload
      );

      this.dispatch(setSquaresDashboard(squares));
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares dashboard : ' + msg
      );
      return [];
    }
  }

  async getDashboardAssetsData(selectedStartDate: Date, selectedEndDate: Date) {
    try {
      const payload: ICreateSquaresPayload = {
        selectedStartDate,
        selectedEndDate,
        customerName: this.customer,
      };
      const squares = await this.apiDashboard.getDashboardAssetsData(payload);

      this.dispatch(setSquaresAssetsDashboard(squares));
      setSquaresAssetsDashboard(squares);
      this.handleSuccess();
      return squares;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares dashboard : ' + msg
      );
      return [];
    }
  }

  async getHealthScoreData() {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSquaresPayload = {
        customerName: this.customer,
      };
      const healthScoreData = await this.apiDashboard.getHealthScoreData(
        payload
      );
      this.dispatch(setHealthScore(healthScoreData));
      this.handleSuccess();
      this.dispatch(updateLoadingState(false));

      return healthScoreData;
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the health score datas : ' + msg
      );
      return [];
    }
  }
}
