import BaseAPI from './base.api';

export default class ProductionAPI extends BaseAPI {
  getTotalFleet = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('production/total-fleet', {
      params: {
        ...params,
      },
    });
    return response.data;
  };

  getTotalFleetSummary = async (
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean
  ) => {
    let params: any = {
      range,
    };
    if (shouldSendDate) {
      params = {
        startDate,
        endDate,
      };
    }
    const response = await this.API.get('production/date-range-summary', {
      params: {
        ...params,
      },
    });
    return response.data;
  };

  getTotalFleetProduction = async () => {
    const response = await this.API.get('production/ytd-summary');
    return response.data;
  };

  getFleetPeriodSummary = async () => {
    const response = await this.API.get('production/fleet-period-summary');
    return response.data;
  };
}
