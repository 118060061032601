import { Typography } from '@mui/material';
import { type Asset } from 'types/asset.types';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import { SWToolTip } from 'components/SWToolTip';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  isLoadingAllAssets: boolean;
  dateComparer: string;
  assetNotifications: any;
  handleAlertClick: any;
}

const AssetCardMaintenance = ({
  asset,
  enableDarkTheme = false,
  isLoadingAllAssets,
  dateComparer,
  assetNotifications,
  handleAlertClick,
}: Props) => {
  const activeNotifications = assetNotifications.filter(
    (item: any) => item.isActive
  );
  const { color, warning } = notificationLevel(activeNotifications);
  return (
    <SWToolTip
      title={`Click for drill-down to view Notifications for ${asset.bumperNumber}`}
    >
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
          }}
        >
          Maintenance
        </Typography>
        <Typography
          style={{
            fontSize: '16px',
          }}
        >
          <span
            onClick={warning !== 'No Alerts' ? handleAlertClick : null} // Conditionally set onClick handler
            style={{
              fontSize: '10px',
              cursor: warning !== 'No Alerts' ? 'pointer' : 'default', // Conditionally change cursor
              color,
            }}
          >
            {warning}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          &nbsp;
        </Typography>
      </div>
    </SWToolTip>
  );
};

export default AssetCardMaintenance;
