export function getDataKeyResponse(type: string) {
  if (type === 'Production') {
    return 'productionValue';
  }

  if (type === 'Fuel') {
    return 'avg_fuel_consumption_per_day';
  }

  if (type === 'Utilizaton') {
    return 'avg_utilization_by_whole_day';
  }

  if (type === 'Idling') {
    return 'avg_max_load_per_day';
  }
  return 'avg_max_load_per_day';
}

export function getDataUnit(type: string, unitSystem: string) {
  if (type === 'Production') {
    return unitSystem === 'imperial' ? 'lbs' : 'Tons';
  }

  if (type === 'Fuel') {
    return unitSystem === 'imperial' ? 'gal' : 'l';
  }

  if (type === 'Utilization') {
    return unitSystem === 'imperial' ? 'lbs/gal' : 'Tons/l';
  }

  if (type === 'Idling') {
    return 'mins';
  }
}

export function convertNanosecondsToMinutes(nanoseconds: any) {
  const seconds = nanoseconds / 1_000_000_000; // Convert nanoseconds to seconds
  const minutes = seconds / 60; // Convert seconds to minutes
  return minutes;
}

export function percentageLeft(current: any, target: any) {
  if (target === 0) return 0; // Avoid division by zero
  const left = ((target - current) / target) * 100;
  return left > 0 ? left : 0; // If current exceeds target, return 0
}
