import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { IdlingAssetData, Data } from 'types/FleetOverview.types';
import type { Asset } from 'types/asset.types';
import type { Device as DeviceType } from 'types/device.types';
import { isEmpty } from 'utils/helpers/hashmaps';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
// import { getAssetsCommmonTags } from 'utils/helpers/device';
import { addLiveDataToAssets } from 'views/FleetOverview/helpers/asset.helpers';

export interface AssetState {
  assets: Asset[];
  assetsWithExcessiveIdling: IdlingAssetData[];
  assetTypeFilter: Record<string, boolean>;
  assetSelected: string;
  assetMakeModelFilter: Record<string, boolean>;
  assetAlertStatusFilter: Record<string, boolean>;
  /* Below is not supported */
  assetStatusFilter: Record<string, boolean>;
  assetDeviceStatusFilter: Record<string, boolean>;
  assetsLoaded: boolean;
  assetOverView: any;
  isLoadingAllAssets: boolean;
  assetComparison: {
    score: any;
    metrics: Data;
    loading: boolean;
    deviceTags: string[];
    tags: Array<{
      loading: boolean;
      metrics: Data;
    }>;
  };
}

const initialState: AssetState = {
  assets: [],
  assetsWithExcessiveIdling: [],
  assetTypeFilter: {},
  assetSelected: '',
  assetMakeModelFilter: {},
  assetStatusFilter: {},
  assetDeviceStatusFilter: {},
  assetAlertStatusFilter: {},
  assetsLoaded: false,
  assetOverView: {
    fuelScore: {},
    productivityScore: {},
    utilizationScore: {},
  },
  isLoadingAllAssets: false,
  assetComparison: {
    score: {},
    metrics: {},
    loading: false,
    deviceTags: [],
    tags: [],
  },
};

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    appendAsset(state, { payload }) {
      state.assets.push(payload);
    },
    updateAssetList(state, { payload }: { payload: Asset }) {
      // remove Operators From Existing Assets;
      payload?.operators?.forEach((operator) => {
        state.assets.filter((asset: Asset) => {
          if (asset.assetId !== payload.assetId) {
            asset?.operators.filter((assetOperator) => {
              if (assetOperator.id === operator.id && assetOperator) {
                const indexOfAssetOperator =
                  state.assets[state.assets.indexOf(asset)].operators.indexOf(
                    assetOperator
                  );
                state.assets[state.assets.indexOf(asset)].operators.splice(
                  indexOfAssetOperator,
                  1
                );
              }
              return null;
            });
          }
          return null;
        });
      });

      const index = state.assets.findIndex(
        (asset) => asset.assetId === payload.assetId
      );

      if (index === -1) {
        // do not update the state if the asset is not found
        return;
      }

      state.assets[index] = payload;
    },
    setAssets(state, { payload }) {
      state.assets = payload;
    },

    setAssetsLoaded(state, { payload }) {
      state.assetsLoaded = payload;
    },

    removeAsset(state, { payload }: { payload: { assetId: string } }) {
      const filtedAssets = state.assets.filter(
        (asset) => asset.assetId !== payload.assetId
      );
      state.assets = filtedAssets;
    },

    //  Asset With Excessive idling time
    setAssetsWithExcessiveIdling(
      state,
      { payload }: { payload: IdlingAssetData[] }
    ) {
      state.assetsWithExcessiveIdling = payload;
    },

    /*
      ASSET FILTERS

      If filter exists, then remove.
      If it does not exist, add it
    */
    updateAssetTypeFilter(state, { payload }: { payload: string }) {
      state.assetTypeFilter[payload] = !state.assetTypeFilter[payload];
    },
    setAssetSelected(state, { payload }) {
      state.assetSelected = payload.name;
    },
    updateAlertStatusFilter(state, { payload }: { payload: string }) {
      state.assetAlertStatusFilter[payload] =
        !state.assetAlertStatusFilter[payload];
    },
    updateAssetMakeModelFilter(state, { payload }: { payload: string }) {
      // omit other filter
      if (payload === 'other') return;

      state.assetMakeModelFilter[payload] =
        !state.assetMakeModelFilter[payload];
    },
    updateAssetDeviceStatusFilter(state, { payload }: { payload: string }) {
      state.assetDeviceStatusFilter[payload] =
        !state.assetDeviceStatusFilter[payload];
    },

    /// ///////////  TODO HEEREEEE
    updateAssetWithExcessiveIdling(state, { payload }: { payload: string }) {},

    /*
      Explicitly remove asset filters
    */
    clearAssetTypeFilter(state) {
      state.assetTypeFilter = {};
    },
    clearAssetMakeModelFilter(state) {
      state.assetMakeModelFilter = {};
    },
    clearAssetAlertStatusFilter(state) {
      state.assetAlertStatusFilter = {};
    },
    clearAllFilters(state) {
      state.assetTypeFilter = {};
      state.assetMakeModelFilter = {};
      state.assetStatusFilter = {};
      state.assetDeviceStatusFilter = {};
      state.assetAlertStatusFilter = {};
    },
    updateAssetDeviceDetails(state, { payload }) {
      const updatedAssets = state.assets.map((asset) => {
        if (asset.device.deviceId === payload.deviceId) {
          return {
            ...asset,
            device: {
              ...asset.device,
              ...payload,
            },
          };
        }
        return asset;
      });
      state.assets = updatedAssets;
    },
    setAssetFleetOverview(state, { payload }) {
      state.assetOverView = payload;
    },
    resetAssets(state) {
      state = initialState;
    },
    setIsLoadingAllAssets(state, { payload }) {
      state.isLoadingAllAssets = payload;
    },
    setIsLoadingAssetComparison(state) {
      state.assetComparison = {
        ...state.assetComparison,
        loading: true,
      };
    },
    setAssetComparison(state, { payload }) {
      state.assetComparison = {
        ...state.assetComparison,
        ...payload,
      };
    },
    setLoadedAssetComparison(state) {
      state.assetComparison = {
        ...state.assetComparison,
        loading: false,
      };
    },

    setIsLoadingAssetComparisonByTags(state) {
      state.assetComparison = {
        ...state.assetComparison,
        tags: [
          ...state.assetComparison.tags,
          {
            loading: true,
            metrics: {},
          },
        ],
      };
    },
    setAssetComparisonByTags(state, { payload }) {
      state.assetComparison = {
        ...state.assetComparison,
        tags: state.assetComparison.tags.map((tag, index) => {
          if (index === state.assetComparison.tags.length - 1 && tag.loading) {
            return {
              ...tag,
              loading: false,
              metrics: payload.metrics,
            };
          }
          return tag;
        }),
      };
    },
    clearAssetComparisonByTags(state) {
      state.assetComparison = {
        ...state.assetComparison,
        tags: [],
      };
    },
    removeTagFromAssetComparisonByTags(state, { payload }) {
      if (
        Object.prototype.hasOwnProperty.call(
          state.assetComparison.metrics,
          payload
        )
      ) {
        const assetComparison = { ...state.assetComparison };
        // delete assetComparison.metrics[payload];
        const { [payload]: _, ...newMetrics } = assetComparison.metrics;
        state.assetComparison = {
          ...state.assetComparison,
          metrics: newMetrics,
        };
      } else {
        state.assetComparison.tags.forEach((item) => {
          if (item?.metrics && typeof item.metrics === 'object') {
            if (Object.prototype.hasOwnProperty.call(item.metrics, payload)) {
              // delete item.metrics[payload];
              const { [payload]: _, ...newMetrics } = item.metrics;
              item.metrics = newMetrics;
            }
          }
        });
        state.assetComparison = {
          ...state.assetComparison,
        };
      }
    },
  },
});

export const {
  appendAsset,
  updateAssetList,
  setAssets,
  removeAsset,
  setAssetsWithExcessiveIdling,
  updateAssetTypeFilter,
  updateAlertStatusFilter,
  updateAssetMakeModelFilter,
  updateAssetDeviceStatusFilter,
  clearAllFilters,
  updateAssetDeviceDetails,
  updateAssetWithExcessiveIdling,
  resetAssets,
  setAssetsLoaded,
  setAssetSelected,
  setAssetFleetOverview,
  setIsLoadingAllAssets,
  setIsLoadingAssetComparison,
  setAssetComparison,
  setLoadedAssetComparison,
  setIsLoadingAssetComparisonByTags,
  setAssetComparisonByTags,
  clearAssetComparisonByTags,
  removeTagFromAssetComparisonByTags,
} = assetSlice.actions;

/* Define input selectors */
export const selectAssets = (state: RootState) => state.assetReducer.assets;
export const selectAssetsWithExcessiveIdling = (state: RootState) =>
  state.assetReducer.assetsWithExcessiveIdling;
export const selectLatestTags = (state: RootState) =>
  state.deviceReducer.latestTagData;
export const selectAssetTypeFilter = (state: RootState) =>
  state.assetReducer.assetTypeFilter;
export const selectAssetMakeModelFilter = (state: RootState) =>
  state.assetReducer.assetMakeModelFilter;
export const selectDeviceStatusFilter = (state: RootState) =>
  state.assetReducer.assetDeviceStatusFilter;
export const selectNotifications = (state: RootState) =>
  state.ruleNotificationsReducer.notifications;
export const selectAssetAlertStatusFilter = (state: RootState) =>
  state.assetReducer.assetAlertStatusFilter;

// filter out assets based on the filters
export const selectFilteredAssets = createSelector(
  [
    selectAssets,
    selectAssetTypeFilter,
    selectAssetMakeModelFilter,
    selectAssetAlertStatusFilter,
    selectDeviceStatusFilter,
    selectLatestTags,
    selectNotifications,
  ],
  (
    assets,
    assetTypeFilter,
    assetMakeModelFilter,
    assetAlertStatusFilter,
    assetDeviceStatusFilter,
    selectLatestTags,
    selectNotifications
  ) => {
    /* Return early if there are no filters */
    if (
      isEmpty(assetTypeFilter) &&
      isEmpty(assetMakeModelFilter) &&
      isEmpty(assetDeviceStatusFilter) &&
      isEmpty(assetAlertStatusFilter)
    ) {
      return assets;
    }
    const assestsWithLiveTags = addLiveDataToAssets(
      assets,
      selectLatestTags,
      selectNotifications
    );

    const filteredAssets = assestsWithLiveTags.filter((asset) => {
      /*
        find make and model
        we join make and model using `!` as a delimiter
        b/c we can guarantee it will not be included
        in an actual make or model
       */
      const makeModel = composeUniqueKey([asset.make, asset.model]);
      const deviceStatus = asset?.device?.deviceStatus;
      const notifications = asset.notifications ?? [];

      if (
        notifications.length > 0 &&
        notifications.some(
          (el: any) => assetAlertStatusFilter[el.rule.notificationLevel]
        )
      ) {
        return false;
      } else if (notifications.length === 0 && assetAlertStatusFilter['0']) {
        return false;
      }
      if (assetTypeFilter[asset.assetType]) return false;
      if (assetMakeModelFilter[makeModel]) return false;
      /*
        Filter is true/false for device status
        true = online, false = offline  
      */
      if (assetDeviceStatusFilter[deviceStatus?.toString().toLowerCase()])
        return false;

      return true;
    });

    return filteredAssets;
  }
);

export const selectAssociatedFilteredAssets = createSelector(
  [selectFilteredAssets, selectLatestTags, selectNotifications],
  (assets, selectLatestTags, selectNotifications) => {
    const tmp = addLiveDataToAssets(
      assets,
      selectLatestTags,
      selectNotifications
    ).filter((asset) => asset.device?.deviceId);

    return tmp.filter((asset) => asset.device?.deviceId);
  }
);
/*
 * Selectors for associated devices
 * @param {Asset[]} assets
 * @returns {DeviceType[]} devices
 */
export const selectAssociatedDevices = createSelector(
  selectAssets,
  (assets) => {
    const devices: Array<DeviceType & { assetId: string }> = [];
    assets.forEach((asset: Asset) =>
      asset?.device?.deviceId ? devices.push({ ...asset.device }) : null
    );
    return devices;
  }
);

// todo asset fetch for device + tags need to return only "active" tags
// Filter out assets without tags
export const selectAssetsWithTags = createSelector(selectAssets, (assets) => {
  const assetsWithTags = assets.filter((asset) => asset.device?.tags?.length);
  return assetsWithTags;
});

/**
 * Selectors for unassociated assets
 * @param {Asset[]} assets
 * @returns {Asset[]} unassociatedAssets
 * @description
 * 1. Filter out assets with associated devices
 * 2. Return assets without associated devices
 */
export const selectUnassociatedAssets = createSelector(
  [selectAssets],
  (assets) => {
    const unassociatedAssets = assets.filter(
      (asset) => !asset.device?.deviceId
    );
    return unassociatedAssets;
  }
);

/**
 * Selectors for associated assets
 * @param {Asset[]} assets
 * @returns {Asset[]} associatedAssets
 * @description
 * 1. Filter out assets with associated devices
 * 2. Return assets with associated devices
 */
export const selectAssociatedAssets = createSelector(
  [selectAssets, selectLatestTags, selectNotifications],
  (assets, selectLatestTags, selectNotifications) => {
    const associatedAssets = assets.filter((asset) => asset.device?.deviceId);
    return addLiveDataToAssets(
      associatedAssets,
      selectLatestTags,
      selectNotifications
    );
  }
);

/**
 * Selector for assets by make and model
 * @param {Asset[]} assets
 *
 * @description
 * 1. Filter assets with devices
 * 2. Create a hashmap of all valid make and models
 * 3. Add assets to hashmap with devices and tags
 * 4. Return hashmap
 */
export const selectAssetByMakeModel = createSelector(
  [selectAssets],
  (assets) => {
    const map: Record<string, Asset[]> = {};
    const assetMakeModelMap = assets
      .filter((asset) => asset.device?.deviceId)
      .reduce((acc, asset) => {
        /*
        find make and model
        we join make and model using `!` as a delimiter
        b/c we can guarantee it will not be included
        in an actual make or model
       */
        const makeModel = composeUniqueKey([asset.make, asset.model]);

        if (!acc[makeModel]) acc[makeModel] = [];

        acc[makeModel].push(asset);

        return acc;
      }, map);

    return assetMakeModelMap;
  }
);

export default assetSlice.reducer;
