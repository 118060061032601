import React, { useState } from 'react';
import './ServiceTimeline.css';
import { Tooltip, Typography, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type HealthScore } from 'types/squares.type';

interface TimelineItemProps {
  label: string;
  services: HealthScore[];
  enableDarkTheme: boolean;
  onMonthSelect: (month: string | null) => void;
  isSelected: boolean; // Prop to determine if this item is selected
}

export const SquareServiceTimeline: React.FC<{
  services: HealthScore[];
  enableDarkTheme: boolean;
  onMonthSelect: (month: string | null) => void;
}> = ({ services, enableDarkTheme, onMonthSelect }) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // State to manage the selected month
  const [selectedMonth, setSelectedMonth] = useState<string | null>('All');

  // Helper function to parse date strings and return month and year
  const parseDate = (dateString: string) => {
    const date = new Date(dateString);
    return {
      month: date.getUTCMonth(),
      year: date.getUTCFullYear(),
    };
  };

  // Order months to start from the current month
  const orderedMonths = [
    ...months.slice(currentMonth).map((month) => `${month} ${currentYear}`),
    ...months
      .slice(0, currentMonth)
      .map((month) => `${month} ${currentYear + 1}`),
  ];

  const handleMonthSelect = (month: string | null) => {
    // If "All" is clicked, reset to "All" state
    const selected = month ?? 'All';
    setSelectedMonth(selected);
    onMonthSelect(selected === 'All' ? null : selected); // Pass null if "All" is selected
  };

  return (
    <div
      className={
        enableDarkTheme ? 'timeline-container-dark' : 'timeline-container'
      }
    >
      {/* "All" bubble */}
      <TimelineItem
        label="All"
        services={services}
        enableDarkTheme={enableDarkTheme}
        onMonthSelect={handleMonthSelect}
        isSelected={selectedMonth === 'All'} // Pass selected state
      />
      {orderedMonths.map((monthLabel, index) => {
        const [monthName, year] = monthLabel.split(' ');
        const monthIndex = months.indexOf(monthName);
        const yearNumber = parseInt(year, 10);

        return (
          <TimelineItem
            key={index}
            label={monthLabel}
            services={services.filter((service) => {
              const { month, year } = parseDate(
                service.asset_details.squares_recommended_date
              );
              return month === monthIndex && year === yearNumber;
            })}
            enableDarkTheme={enableDarkTheme}
            onMonthSelect={handleMonthSelect}
            isSelected={selectedMonth === monthLabel} // Pass selected state
          />
        );
      })}
    </div>
  );
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  label,
  services,
  enableDarkTheme,
  onMonthSelect,
  isSelected,
}) => {
  const handleClick = () => {
    // Handle selection of the month
    onMonthSelect(label === 'All' ? null : label);
  };

  return (
    <div className="timeline-item">
      <Tooltip
        title={
          services.length
            ? services
                .map((service) => service.asset_details.bumper_number)
                .join(', ')
            : 'No assets'
        } // Show bumper numbers in tooltip or "No assets" if empty
        arrow
        placement="top"
      >
        <Box
          className={
            enableDarkTheme ? 'circle circle-dark' : 'circle circle-light'
          }
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: isSelected ? '#007bff' : '', // Blue if selected
            color: isSelected
              ? '#ffffff'
              : enableDarkTheme
              ? ThemePalette.typography.black
              : ThemePalette.typography.white,
            border: isSelected
              ? 'none'
              : `1px solid ${ThemePalette.typography.grey}`,
            transition: 'background-color 0.3s, color 0.3s', // Smooth transition
          }}
        >
          <Typography variant="body2">{services.length}</Typography>
        </Box>
      </Tooltip>
      <div className="label">
        <Typography variant="body2">{label}</Typography>
      </div>
    </div>
  );
};

export default SquareServiceTimeline;
