// src/components/AssetDetailsTable.tsx
import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { GetIcons } from 'views/SurvivalAnalysis/Helper/Icons/GetIcons.function';
import componentsImage from 'views/SurvivalAnalysis/Helper/Icons/componenent.png';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import { type ComponentDetails } from 'types/squares.type';
import { SVG } from 'components/Asset/SVG';
import { type ServiceView } from 'types/survivalAnalysis.types';

interface AssetDetailsTableProps {
  enableDarkTheme: boolean;
  details: ComponentDetails[];
  filteredAssets: any[];
}

const AssetDetailsTable: React.FC<AssetDetailsTableProps> = ({
  enableDarkTheme,
  details,
  filteredAssets,
}) => {
  const getBorderColor = (criticality: string): string => {
    switch (criticality.toLowerCase()) {
      case 'low':
        return ThemePalette.typography.success;
      case 'med':
        return ThemePalette.typography.warning;
      default:
        return ThemePalette.typography.danger;
    }
  };
  function getWorkOrderAgeCategory(
    assetId: string,
    componentsCategory: string,
    subComponent: string
  ) {
    if (!filteredAssets || filteredAssets.length === 0) {
      console.error('filteredAssets is empty or undefined.');
      return null;
    }

    const result = filteredAssets.find(
      (item) =>
        item.asset_id === assetId &&
        item.components_category === componentsCategory &&
        item.sub_component === subComponent
    );

    if (!result) {
      console.error('No matching item found for:', {
        assetId,
        componentsCategory,
        subComponent,
      });
      return null;
    }

    let riskValue = result.work_order_age_category ?? null;

    if (riskValue === 'eol') {
      riskValue = 'high';
    }
    return riskValue ? riskValue.toUpperCase() : null;
  }

  const enhancedDetails = details.slice(0, 3).map((detail) => ({
    ...detail,
    work_order_age_category: getWorkOrderAgeCategory(
      detail.bumper_number,
      detail.component,
      detail.sub_component
    ),
  }));

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table
        style={{
          background: enableDarkTheme
            ? ThemePalette.dark.tableBackground
            : ThemePalette.light.tableBackground,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Component
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Recommended Service Frequency
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Age (Last Maintenance)
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Manufacturer Rec. Date
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Squares Recommended Date
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Risk
            </TableCell>
            <TableCell
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.grey,
              }}
            >
              Criticality
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {enhancedDetails.map((detail, index) => (
            <TableRow
              key={`${detail.bumper_number}-${index}`}
              sx={{
                borderLeft: `4px solid ${getBorderColor(detail.criticality)}`,
              }}
            >
              {/* Component Details */}
              <TableCell>
                <Box display="flex" alignItems="center" gap={2}>
                  <GetIcons
                    icon={componentsImage}
                    enableDarkTheme={enableDarkTheme}
                  />
                  <Box>
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail.component}
                    </Typography>
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.grey,
                      }}
                      variant="body2"
                    >
                      {detail.sub_component}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>

              {/* Recommended Service Frequency */}
              <TableCell>
                <Box>
                  {!detail.freq_calendar_days && !detail.freq_engine_hours && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      On Need Basis
                    </Typography>
                  )}

                  {detail.freq_calendar_days && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      <CalendarToday sx={{ fontSize: '12px' }} />
                      {` Every ${detail.freq_calendar_days}`}
                      {detail.freq_engine_hours &&
                        detail.freq_calendar_days && <span> or </span>}
                    </Typography>
                  )}

                  {detail.freq_engine_hours && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      <SVG name="streetIcon" />
                      {` Every ${detail.freq_engine_hours}`}
                    </Typography>
                  )}
                  {detail.maintenance_type && (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body2"
                    >
                      {detail.maintenance_type}
                    </Typography>
                  )}
                </Box>
              </TableCell>

              {/* Age (Last Maintenance) */}
              <TableCell>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  <SVG name="engineLight" /> {Number(detail.age_in_days) * 24}{' '}
                  Hours
                </Typography>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                  variant="body2"
                >
                  <CalendarToday sx={{ fontSize: '12px' }} />{' '}
                  {new Date(
                    new Date().setDate(
                      new Date().getDate() - Number(detail.age_in_days)
                    )
                  ).toLocaleDateString()}
                </Typography>
              </TableCell>

              {/* Manufacturer Rec. Date */}
              <TableCell>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    variant="body1"
                  >
                    {detail.manufacture_recommended_date}
                  </Typography>
                  <Typography
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.grey,
                    }}
                    variant="subtitle2"
                    color="grey"
                  >
                    {(() => {
                      const daysDifference = Math.ceil(
                        (new Date(
                          detail.manufacture_recommended_date
                        ).getTime() -
                          new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      );

                      if (daysDifference > 0) {
                        return `${daysDifference} Days from now`;
                      } else if (daysDifference < 0) {
                        return `${Math.abs(daysDifference)} Days overdue`;
                      } else {
                        return 'Immediate';
                      }
                    })()}
                  </Typography>
                </Box>
              </TableCell>

              {/* Squares Recommended Date */}
              <TableCell>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail.squares_recommended_date}
                </Typography>
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                  variant="subtitle2"
                  color="grey"
                >
                  {(() => {
                    const daysDifference = Math.ceil(
                      (new Date(detail.squares_recommended_date).getTime() -
                        new Date().getTime()) /
                        (1000 * 60 * 60 * 24)
                    );

                    if (daysDifference > 0) {
                      return `${daysDifference} Days from now`;
                    } else if (daysDifference < 0) {
                      return `${Math.abs(daysDifference)} Days overdue`;
                    } else {
                      return 'Immediate';
                    }
                  })()}
                </Typography>
              </TableCell>

              {/* Risk */}
              <TableCell>
                <Typography
                  style={{
                    color:
                      detail.work_order_age_category === 'HIGH'
                        ? getBorderColor(detail.work_order_age_category)
                        : detail.work_order_age_category === 'MED'
                        ? getBorderColor(detail.work_order_age_category)
                        : detail.work_order_age_category === 'LOW'
                        ? getBorderColor(detail.work_order_age_category)
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail.work_order_age_category}{' '}
                  {/* Use the pre-calculated value */}
                </Typography>
              </TableCell>

              {/* Criticality */}
              <TableCell>
                <Typography
                  style={{
                    color:
                      detail.criticality === 'high'
                        ? getBorderColor(detail.criticality)
                        : detail.criticality === 'med'
                        ? getBorderColor(detail.criticality)
                        : detail.criticality === 'low'
                        ? getBorderColor(detail.criticality)
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {detail.criticality.toUpperCase()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetDetailsTable;
