import React, { useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Sector,
  Tooltip,
} from 'recharts';
import { type AssetData } from '../utils';
import {
  darkenColor,
  handleFocusRemoveSelectOutline,
} from '../Helper/pieChart.Helper';
import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = Number(cx) + (Number(outerRadius) + 10) * cos;
  const sy = Number(cy) + (Number(outerRadius) + 10) * sin;
  const mx = Number(cx) + (Number(outerRadius) + 30) * cos;
  const my = Number(cy) + (Number(outerRadius) + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={payload.name === 'total_part_cost' ? 14 : 0}
        textAnchor="middle"
        fill={fill}
      >
        {getName(payload.name)}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={Number(outerRadius) + 6}
        outerRadius={Number(outerRadius) + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-12}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Value:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={14}
      >{`${value.toFixed(0)}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={15}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`Rate`}
      </text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={27}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={14}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
}> = ({ active, payload, label, enableDarkTheme = false }) => {
  if (active && payload && payload.length) {
    const hoverCell = payload[0].payload;

    return (
      <div className="custom-tooltip">
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Asset Name: {getName(hoverCell.name)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Total Asset Cost: {hoverCell.value}
        </Typography>
      </div>
    );
  }
  return null;
};

export const ComponentsPieChart = ({
  data,
  handlePieClick,
  selectedAssetsCostType,
  enableDarkTheme = false,
}: {
  data: AssetData[];
  handlePieClick: any;
  selectedAssetsCostType: string[];
  enableDarkTheme: boolean;
}) => {
  const [activeIndices, setActiveIndices] = useState<Record<number, boolean>>({
    0: true, // Set the first segment as active
    1: true,
  });

  const onPieClick = (data: any, index: number) => {
    setActiveIndices((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the active state for the clicked segment
    }));
    handlePieClick(data);
  };

  const aggregatedData = [
    {
      name: 'total_labour_cost',
      value: data.reduce((sum, current) => sum + current.total_labour_cost, 0),
    },
    {
      name: 'total_part_cost',
      value: data.reduce((sum, current) => sum + current.total_part_cost, 0),
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <div tabIndex={0} onFocus={handleFocusRemoveSelectOutline}>
        <PieChart width={400} height={300}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={aggregatedData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            onClick={onPieClick}
            activeIndex={Object.keys(activeIndices)
              .map(Number)
              .filter((i) => activeIndices[i])} // Pass active indices
            activeShape={renderActiveShape}
          >
            {aggregatedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  activeIndices[index]
                    ? darkenColor(getColourByIndex(index))
                    : getColourByIndex(index)
                }
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip
            content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
          />
        </PieChart>
      </div>
    </ResponsiveContainer>
  );
};

function getColourByIndex(index: any) {
  return index === 0 ? '#00C49F' : '#FFBB28';
}

function getName(name: string) {
  return name === 'total_part_cost' ? 'Part Cost' : 'Labour Cost';
}

export default ComponentsPieChart;
