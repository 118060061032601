import { Box } from '@mui/material';
import AssetItemDetails from './AssetItem/AssetItemDetails';
import AssetItemChart from './AssetItem/AssetItemChart';
import { ThemePalette } from 'mui.theme';
import type { productionAsset } from 'types/production.types';

interface Props {
  enableDarkTheme: boolean;
  data: productionAsset;
  selectedSource: string;
  unitSystem: string;
}

const AssetItem = ({
  enableDarkTheme,
  data,
  selectedSource,
  unitSystem,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? '#19304F'
          : ThemePalette.light.toolBarBackground,
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '10px',
      }}
    >
      <AssetItemDetails
        enableDarkTheme={enableDarkTheme}
        data={data}
        unitSystem={unitSystem}
      />
      <AssetItemChart
        enableDarkTheme={enableDarkTheme}
        data={data}
        selectedSource={selectedSource}
        unitSystem={unitSystem}
      />
    </Box>
  );
};

export default AssetItem;
