import React from 'react';
import { type LabelProps, Rectangle } from 'recharts';
import { type AssetsOverview } from 'types/survivalAnalysis.types';
import { format, addDays, differenceInDays, addYears, min } from 'date-fns';
import { ThemePalette } from 'mui.theme';
import { riskDays } from './helper.function';

export const CustomXAxisTick = (props: any) => {
  const { x, y, payload, backgroundWidth, backgroundHeight, formattedTicks } =
    props;

  // Get the formatted date from the formattedTicks array using the payload index
  const formattedDate = formattedTicks[payload.value];

  return (
    <g transform={`translate(${x},${y})`}>
      <Rectangle
        x={-5}
        y={-30}
        width={backgroundWidth}
        height={36}
        fill={ThemePalette.dark.menuOptions} // Background color
        radius={5}
      />
      <text
        x={18}
        y={-5}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={15}
      >
        {formattedDate}
      </text>
    </g>
  );
};

export const CustomYAxisTick = (props: any) => {
  const {
    x,
    y,
    payload,
    backgroundWidth,
    backgroundHeight,
    enableDarkTheme,
    detailType,
  } = props;
  const [componentsCategory, subComponentCategories, asset] =
    payload.value.split(' | ');

  return (
    <g transform={`translate(${x},${y - backgroundHeight / 2})`}>
      <Rectangle
        x={-backgroundWidth / 2}
        y={0}
        width={backgroundWidth}
        height={backgroundHeight}
        fill="rgba(0, 0, 0, 0)" // Background color
      />
      <text
        x={x}
        y={backgroundHeight / 2}
        fill={
          enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black
        }
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={10}
      >
        {detailType !== 'Assets' ? (
          <>
            <tspan x={-x / 2} dy={-15} fontSize={10}>
              {asset}
            </tspan>
            <tspan x={-x / 2} dy={12} fontSize={12}>
              {componentsCategory}
            </tspan>
            <tspan x={-x / 2} dy={12} fontSize={10}>
              {subComponentCategories}
            </tspan>
          </>
        ) : (
          <>
            <tspan x={-x / 2} dy={-5} fontSize={12}>
              {componentsCategory}
            </tspan>
            <tspan x={-x / 2} dy={12} fontSize={10}>
              {subComponentCategories}
            </tspan>
          </>
        )}
      </text>
    </g>
  );
};

export const renderCustomLabel = (
  props: LabelProps & { customKey: string }
) => {
  const {
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    customKey = '',
    value = 0,
  } = props;
  if (value === 0) {
    return null;
  }
  const offset = Number(height) / 2;
  const labelMap: Record<string, string> = {
    low: 'low',
    med: 'med',
    high: 'high',
    eol: 'eol',
  };

  return (
    <text
      x={Number(x) + Number(width) / 2}
      y={Number(y) + offset}
      fill="#000"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {labelMap[customKey]}
    </text>
  );
};

interface ProcessedData {
  name: string;
  start: number;
  duration: number;
  date: string;
  graphEndDate: string;
  todaysDate: Date;
  orderDate: Date;
  low: number;
  med: number;
  high: number;
  eol: number;
}

export const processData = (
  components: AssetsOverview[],
  startingDate: Date,
  endDate: Date
): ProcessedData[] => {
  const processedData: ProcessedData[] = [];

  components.forEach((item) => {
    const todaysDate = startingDate;
    const orderDate = new Date(item.date_of_orders);
    const componentEndDate = addDays(todaysDate, item.work_order_age_in_days);
    const graphEndDate = min([componentEndDate, endDate]);
    const graphEndDateFormatted = format(graphEndDate, 'dd MMM yyyy');

    let durationInDays = differenceInDays(todaysDate, orderDate);

    durationInDays = durationInDays >= 365 ? 365 : durationInDays;

    const low = riskDays.low.days;
    const med = riskDays.med.days;
    const high = riskDays.high.days;

    let obj = {
      asset_id: item.asset_id,
      name: `${item.components_category} | ${item.sub_component} | ${item.asset_id}`,
      start: 0,
      duration: durationInDays,
      date: format(todaysDate, 'MMM yyyy'),
      graphEndDate: graphEndDateFormatted,
      todaysDate: todaysDate,
      orderDate: orderDate,
      low: low,
      med: med,
      high: high,
      eol: 0,
    };

    obj = fixList(obj);

    processedData.push(obj);
  });

  return processedData;
};

function fixList(obj: any) {
  let remainingDuration = obj.duration;
  if (remainingDuration > obj.low) {
    remainingDuration -= obj.low;
    obj.low = 0;
  } else {
    obj.low -= remainingDuration;
    remainingDuration = 0;
  }

  if (remainingDuration > 0) {
    if (remainingDuration > obj.med) {
      remainingDuration -= obj.med;
      obj.med = 0;
    } else {
      obj.med -= remainingDuration;
      remainingDuration = 0;
    }
  }

  if (remainingDuration > 0) {
    if (remainingDuration > obj.high) {
      remainingDuration -= obj.high;
      obj.high = 0;
    } else {
      obj.high -= remainingDuration;
      remainingDuration = 0;
    }
  }

  obj.eol = 365 - (Number(obj.low) + Number(obj.med) + Number(obj.high));

  return obj;
}
