import ProductionAPI from 'api/production.api';
import {
  setTotalFleetAssets,
  setTotalFleetSummary,
  setTotalFleetDetails,
  setIsLoading,
  setFleetPeriodSummary,
} from 'store/production.slice';

import { BaseHandler } from './base.handler';

export default class ProductionHandler extends BaseHandler {
  private readonly api: ProductionAPI;

  constructor() {
    super();

    this.api = new ProductionAPI();
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean
  ): Promise<any> {
    let stateChanges: any = {
      isLoadingFleetAssets: true,
      isLoadingFleetSummary: true,
    };

    if (initialRun) {
      stateChanges = {
        ...stateChanges,
        isLoadingFleetDetails: true,
        isLoadingFleetPeriodSummary: true,
      };
    }
    this.dispatch(setIsLoading(stateChanges));
    try {
      if (initialRun) {
        const { totalFleetDetails } = await this.api.getTotalFleetProduction();
        this.dispatch(setTotalFleetDetails(totalFleetDetails));
        this.dispatch(
          setIsLoading({
            isLoadingFleetDetails: false,
          })
        );

        const { data: fleetPeriod } = await this.api.getFleetPeriodSummary();

        this.dispatch(setFleetPeriodSummary(fleetPeriod));
        this.dispatch(
          setIsLoading({
            isLoadingFleetPeriodSummary: false,
          })
        );
      }

      const fleetSummary = await this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate
      );

      this.dispatch(setTotalFleetSummary(fleetSummary));

      this.dispatch(
        setIsLoading({
          isLoadingFleetSummary: false,
        })
      );

      this.dispatch(setIsLoading(false));

      const { assets } = await this.api.getTotalFleet(
        range,
        startDate,
        endDate,
        shouldSendDate
      );

      this.dispatch(setTotalFleetAssets(assets));

      this.dispatch(
        setIsLoading({
          isLoadingFleetAssets: false,
        })
      );

      return assets;
    } catch (_) {
      this.handleError('An error occurred while fetching production details');
      this.dispatch(
        setIsLoading({
          isLoadingFleetAssets: false,
          isLoadingFleetSummary: false,
          isLoadingFleetDetails: false,
          isLoadingFleetPeriodSummary: false,
        })
      );
    }

    return {};
  }
}
