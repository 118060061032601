import * as React from 'react';
import dayjs from 'dayjs';
import { FlexBox } from 'components/Containers/FlexBox';
import PlaceIcon from '@mui/icons-material/Place';
import { Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { ThemePalette } from 'mui.theme';
import { convertCentToFahr } from 'utils/helpers/unitSystem';
import { truncateTo2dp } from 'utils/helpers/general';

export const WeatherDisplay = ({
  data,
  enableDarkTheme,
  customerCode,
}: any) => {
  const currentDate = dayjs().format('DD.MM.YYYY');

  const currentDayWeatherForecast = data?.result?.find(
    (weather: any) => weather.date === currentDate
  );

  const isBuzzi = customerCode === 'buz';

  return (
    <>
      {currentDayWeatherForecast ? (
        <FlexBox
          sx={{
            backgroundColor: enableDarkTheme ? '#3666EC33' : '#3666EC1A',
            marginRight: '40px',
            borderRadius: '5px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          <span
            style={{
              paddingRight: '10px',
              position: 'relative',
              marginLeft: '20px',
            }}
          >
            <img
              src={currentDayWeatherForecast?.icon}
              alt="weather"
              style={{
                height: '50px',
                width: '50px',
              }}
            />
          </span>
          <Typography
            sx={{
              marginRight: '10px',
              paddingTop: '17px',
              fontSize: '14px',
            }}
          >
            {currentDayWeatherForecast?.status}
          </Typography>
          <Typography
            sx={{
              marginRight: '10px',
              paddingTop: '17px',
              fontSize: '14px',
            }}
          >
            {truncateTo2dp(
              convertCentToFahr(currentDayWeatherForecast?.degree)
            )}{' '}
            {isBuzzi ? 'F' : '°C'}
          </Typography>
          <Typography
            sx={{
              marginRight: '10px',
              paddingTop: '17px',
              fontSize: '14px',
            }}
          >
            {truncateTo2dp(convertCentToFahr(currentDayWeatherForecast?.max))}{' '}
            {isBuzzi ? 'F' : '°C'} /{' '}
            {truncateTo2dp(convertCentToFahr(currentDayWeatherForecast?.min))}{' '}
            {isBuzzi ? 'F' : '°C'}
          </Typography>
          <Typography
            sx={{
              marginRight: '10px',
              paddingTop: '17px',
              fontSize: '14px',
            }}
          >
            Humidity - {currentDayWeatherForecast?.humidity}
          </Typography>
          <PlaceIcon
            sx={{
              marginLeft: '10px',
              color: '#1049E4',
              paddingTop: '14px',
            }}
          />
          <Typography
            sx={{
              marginRight: '20px',
              paddingTop: '17px',
              fontSize: '14px',
            }}
          >
            {capitalizeFirstLetter(data?.city)},{' '}
            {capitalizeFirstLetter(data?.state)}
          </Typography>
        </FlexBox>
      ) : (
        <></>
      )}
    </>
  );
};
