import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { OverviewModalSelector } from './modals/Overview-modal-selector';
import { OverviewModalAsset } from './modals/Overview-modal-asset';
import SurvivalAnalysisOverviewHandler from 'handlers/survivalAnalysisHandlers/survivalAnalysisOverview.handler';
import { useEffect, useState } from 'react';
import { OverviewModalComponent } from './modals/Overview-modal-componenet';
import { useFetchComponentData } from '../Helper/Function/fetch.helper.function';

export const OverviewView = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const fetchComponentData = useFetchComponentData();

  const enableDarkTheme = isDarkTheme(theme);
  const survivalAnalysisHandler = new SurvivalAnalysisOverviewHandler();
  const [selectedAssetFromTimeLine, setSelectedAssetFromTimeLine] =
    useState<string>('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [componenetFinancialMetricData, setComponenetFinancialMetricData] =
    useState(0);

  const fetchData = async () => {
    void survivalAnalysisHandler.getAssetsRiskAnalysis();
    void survivalAnalysisHandler.getCumulativeAssetsPerSubComponent();
    void survivalAnalysisHandler.getEOLs();
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const survivalAnaysisOverview = useAppSelector(
    (state) => state.survivalAnaysisOverview
  );

  const handleOpenInNewClick = (itemId: string) => {
    setSelectedAssetFromTimeLine(itemId);
    setSelectedTabIndex(0);
  };

  useEffect(() => {
    const fetchFinancialMetricData = async () => {
      const today = new Date();
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(today.getFullYear() - 1);

      try {
        const financialMetricData: any = await fetchComponentData(
          oneYearAgo,
          today
        );
        setComponenetFinancialMetricData(financialMetricData);
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    void fetchFinancialMetricData();
  }, []);

  const handleModalSuccess = async () => {
    await fetchData();
  };

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      {/* <OverviewModalSelector
        enableDarkTheme={enableDarkTheme}
        allAssets={survivalAnaysisOverview.assets}
        onOpenInNewClick={handleOpenInNewClick}
        eolDates={survivalAnaysisOverview.eol}
      ></OverviewModalSelector> */}
      <Tabs
        selectedIndex={selectedTabIndex}
        onSelect={(index) => {
          setSelectedTabIndex(index);
        }}
      >
        <TabList>
          <ToolbarContainer
            style={{
              paddingBottom: 0,
            }}
            enableDarkTheme={enableDarkTheme}
          >
            <SWTab enableDarkTheme={enableDarkTheme}>By Assets </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>By Component </SWTab>
          </ToolbarContainer>
        </TabList>

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <OverviewModalAsset
            enableDarkTheme={enableDarkTheme}
            allAssets={survivalAnaysisOverview.assets}
            assetsPerSubComponent={survivalAnaysisOverview.cumulative}
            eolDates={survivalAnaysisOverview.eol}
            selectedAssetFromTimeLine={selectedAssetFromTimeLine}
            onOpenInNewClick={handleOpenInNewClick}
            handleModalSuccess={handleModalSuccess}
          />
        </TabPanel>

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <OverviewModalComponent
            enableDarkTheme={enableDarkTheme}
            allAssets={survivalAnaysisOverview.assets}
            assetsPerSubComponent={survivalAnaysisOverview.cumulative}
            componentFinancialMetricData={componenetFinancialMetricData}
            eolDates={survivalAnaysisOverview.eol}
            handleModalSuccess={handleModalSuccess}
          />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
