import { Grid } from '@mui/material';
import { CustomTabPanel } from '../CustomPanel';
import { ThemePalette } from 'mui.theme';
import FleetProductionDetails from './components/FleetProductionDetails';
import FleetProductionChart from './components/FleetProductionChart';
import AverageCycleTime from './components/AverageCycleTime';
import Suggestions from '../components/Suggestions/Suggestions';
import AnalyticsWrapper from './components/AnalyticsWrapper';
import AssetWrapper from './components/AssetWrapper';
import DateRangeDiv from '../HeaderMenu/DateRangeDiv';
import type {
  fleetData,
  productionAsset,
  productionLoadingStates,
} from 'types/production.types';
import {
  getHaulerSummary,
  getLoaderSummary,
  getProductionAssetsByTypeMapping,
} from './utils';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  endDate: any;
  yesterday: any;
  data: fleetData;
  loadingStates: productionLoadingStates;
  unitSystem: string;
  assetOverview: any;
}

const AllFleet = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  endDate,
  yesterday,
  data,
  loadingStates,
  unitSystem,
  assetOverview,
}: Props) => {
  const haulersSummary: any = getHaulerSummary(data.summary);
  const loaderSummary: any = getLoaderSummary(data.summary);

  const haulerAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Haul Truck'
  );
  const loaderAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Loader'
  );

  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <div>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <FleetProductionDetails
              enableDarkTheme={enableDarkTheme}
              data={data.totalFleetDetails}
              isLoading={loadingStates.isLoadingFleetDetails}
              unitSystem={unitSystem}
            />
            <FleetProductionChart
              enableDarkTheme={enableDarkTheme}
              data={data.fleetPeriodSummary}
              isLoading={loadingStates.isLoadingFleetPeriodSummary}
              unitSystem={unitSystem}
              title="Fleet Production"
            />
          </Grid>

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
          />

          <AnalyticsWrapper
            enableDarkTheme={enableDarkTheme}
            data={data.summary}
            isLoading={loadingStates.isLoadingFleetSummary}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
          />
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <AverageCycleTime
                  enableDarkTheme={enableDarkTheme}
                  title="Average Hauler Cycle Time"
                  data={haulersSummary}
                  isLoading={loadingStates.isLoadingFleetSummary}
                />
                <AverageCycleTime
                  enableDarkTheme={enableDarkTheme}
                  title="Average Loader Cycle Time"
                  data={loaderSummary}
                  isLoading={loadingStates.isLoadingFleetSummary}
                />
              </Grid>
            </Grid>
            <Suggestions enableDarkTheme={enableDarkTheme} minHeight="300px" />
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Haulers"
              data={haulerAssets}
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
            />
            <AssetWrapper
              enableDarkTheme={enableDarkTheme}
              title="Loaders"
              data={loaderAssets}
              isLoading={loadingStates.isLoadingFleetAssets}
              unitSystem={unitSystem}
            />
          </Grid>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default AllFleet;
