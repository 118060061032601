import { Selector } from 'components/Form/Selectors/Selector';

interface Props {
  node: any;
  data: Record<string, any>;
  field: string;
  onSelectorChange: (arg0: any, arg1: any, nodeId?: string) => void;
  fallbackValue?: string;
  selectorOptions: Array<{ id: string; display: string }>;
  enableDarkTheme: boolean;
}

export const SelectorRenderer = ({
  node,
  data,
  field,
  onSelectorChange,
  fallbackValue = '',
  selectorOptions,
  enableDarkTheme = false,
}: Props) => {
  return (
    <Selector
      disabled={!data.isActive}
      formControlSx={{
        minWidth: '100%',
      }}
      onChange={(e) => {
        if (!e.target.value) return;
        if (!onSelectorChange) return;

        onSelectorChange(data, e.target.value, node.id);
      }}
      value={data[field]}
      fallbackValue={fallbackValue}
      selectorOptions={selectorOptions}
      enableDarkTheme={enableDarkTheme}
    />
  );
};
