import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import type { BarProps } from 'recharts'; // Correct type-only import
import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import {
  calculateMonthDifference,
  categoryMapping,
} from '../Function/helper.function';
import { useRef } from 'react';

// Define the shape of data items
interface GraphDataItem {
  month: number;
  cumulative_assets: number;
}

// Define types for CustomTooltip props
interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
}

// Custom Tooltip component
const CustomTooltip = ({
  active,
  payload,
  label,
  enableDarkTheme,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          padding: '10px',
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
          }}
          variant="body2"
        >
          {Number(payload[0].value).toFixed(0)}% Assets to check
        </Typography>
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
          }}
          variant="body2"
        >
          in {label} Month(s)
        </Typography>
      </Box>
    );
  }

  return null;
};

// Function to determine the color based on the month
const getBarColor = (month: number) => {
  if (month <= 1.5) {
    return categoryMapping.low.color;
  } else if (month >= 2 && month <= 3) {
    return categoryMapping.med.color;
  } else {
    return categoryMapping.high.color;
  }
};

// Define the props for CustomBarWithArrow
interface CustomBarWithArrowProps extends BarProps {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  fill?: string;
  payload: GraphDataItem;
  installationMonth: number;
}

// Custom Bar Component with a Flipped Arrow and Gradient
const CustomBarWithArrow: React.FC<CustomBarWithArrowProps> = ({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  payload,
  installationMonth,
}) => {
  // Check if the current bar corresponds to the installation month
  const isInstallationMonth = payload.month === installationMonth;
  const barColor = getBarColor(payload.month);
  const gradientId = `gradient-${payload.month}`; // Create unique ID using month to ensure it applies correctly

  return (
    <g>
      {/* Bar with gradient */}
      <defs>
        <linearGradient id={gradientId} x1="0" y1="1" x2="0" y2="0">
          <stop offset="0%" stopColor={barColor} stopOpacity={0.2} />
          <stop offset="100%" stopColor={barColor} stopOpacity={1} />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={`url(#${gradientId})`}
      />
      {/* Flipped Arrow only on the bar corresponding to the installation month */}
      {isInstallationMonth && (
        <polygon
          points={`${x + width / 2 - 5},${y + height + -90} 
                  ${x + width / 2 + 5},${y + height + -90} 
                  ${x + width / 2},${y + height + -80}`}
          fill={barColor}
        />
      )}
    </g>
  );
};

// Add PropTypes validation
CustomBarWithArrow.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.shape({
    month: PropTypes.number.isRequired,
    cumulative_assets: PropTypes.number.isRequired,
  }).isRequired,
  installationMonth: PropTypes.number.isRequired,
};

export const SurvivalAnalysisBarChart = ({
  generateGraphDataItems,
  component,
  enableDarkTheme,
}: {
  generateGraphDataItems: GraphDataItem[];
  component: { date_of_orders: string };
  enableDarkTheme: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Find the maximum cumulative_assets value
  const maxCumulativeAssets = Math.max(
    ...generateGraphDataItems.map((item) => item.cumulative_assets)
  );

  // Scale the cumulative_assets to percentages
  const scaledData = generateGraphDataItems.map((item) => ({
    ...item,
    cumulative_assets: (item.cumulative_assets / maxCumulativeAssets) * 100,
  }));

  // Convert the date_of_orders from string to Date
  const installationDate = component.date_of_orders;
  const installationMonth = calculateMonthDifference(
    installationDate,
    new Date()
  );

  return (
    <Box
      ref={containerRef}
      sx={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <ResponsiveContainer width="100%" height="75%">
        <ComposedChart
          data={scaledData}
          margin={{
            top: 20,
            bottom: 40, // Increased bottom margin for X-axis label
          }}
        >
          <XAxis
            dataKey="month"
            tickLine={false}
            stroke={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
            label={{
              value: 'Month',
              position: 'bottom',
              offset: -5, // Adjusted offset for the label position
              fill: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: 12,
            }}
          />
          <YAxis
            stroke={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
            domain={[0, 100]}
            tickFormatter={(tick) => `${tick}%`}
          />
          <RechartsTooltip
            content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
          />
          <Bar
            dataKey="cumulative_assets"
            barSize={10}
            isAnimationActive={false}
            shape={(props: any) => (
              <CustomBarWithArrow
                {...(props as CustomBarWithArrowProps)}
                installationMonth={installationMonth}
              />
            )}
          />
          <Line
            type="monotone"
            dataKey="cumulative_assets"
            stroke={enableDarkTheme ? '#8884d8' : '#000000'}
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
