import {
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';

interface Props {
  enableDarkTheme: boolean;
  alert: any;
  isLastItem: boolean;
}

const SuggestionItems = ({ enableDarkTheme, alert, isLastItem }: Props) => {
  return (
    <>
      <ListItem
        sx={{
          borderBottom: isLastItem ? 'none' : '1px solid #2d3b55',
          paddingBottom: '16px',
        }}
      >
        <ListItemIcon>{alert.icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{ color: '#4A7BFA', fontSize: '12px', fontWeight: 'bold' }}
            >
              {alert.code}{' '}
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {alert.message}
              </span>
            </Typography>
          }
          secondary={
            <>
              <Typography
                sx={{ color: '#b0b7c3', fontSize: '10px', paddingTop: '5px' }}
              >
                {alert.description}
              </Typography>
              {alert.action && (
                <Link
                  href="#"
                  style={{
                    color: '#3182ce',
                    fontSize: '10px',
                    textDecoration: 'underline',
                    marginTop: '5px',
                    display: 'block',
                  }}
                >
                  {alert.action}
                </Link>
              )}
            </>
          }
        />
        <Typography sx={{ fontSize: '8px', color: '#b0b7c3' }}>
          {alert.time}
        </Typography>
      </ListItem>
    </>
  );
};

export default SuggestionItems;
