import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  ButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Button,
  type SelectChangeEvent,
} from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { GetIcons } from 'views/SurvivalAnalysis/Helper/Icons/GetIcons.function';
import assetImage from 'views/SurvivalAnalysis/Helper/Icons/vehicle.png';
import { categoryMapping } from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { modalInputFieldStyle } from 'styles/global.css';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import SearchIcon from '@mui/icons-material/Search';
import { type ComponentDetails, type HealthScore } from 'types/squares.type';
import HealthScorePie from '../Helpers/HealthScore.Pie';
import {
  type AssetsOverview,
  type CumulativeOverview,
  type ServiceView,
} from 'types/survivalAnalysis.types';
import { timeAgo } from 'views/FleetOverview/components/AssetCardsV2/utils';
import AssetDetailsTable from './AssetDetailsTable';

interface AssetTableProps {
  assets: HealthScore[];
  filteredAssets: ServiceView[];
  enableDarkTheme: boolean;
  onShowDetails: (itemId: string) => void;
  selectedMonth: string | null;
  assetsDetails: AssetsOverview[];
  assetsPerSubComponent: CumulativeOverview[];
}

const AssetTable: React.FC<AssetTableProps> = ({
  assets,
  filteredAssets,
  enableDarkTheme,
  onShowDetails,
  selectedMonth,
  assetsDetails,
  assetsPerSubComponent,
}) => {
  const healthScorePieRange = [
    { name: 'A', value: 40, color: categoryMapping.high.color },
    { name: 'B', value: 30, color: categoryMapping.med.color },
    { name: 'C', value: 30, color: categoryMapping.low.color },
  ];
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [arrowDirection, setArrowDirection] = useState<Record<number, boolean>>(
    {}
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('Manufacturer Date');
  const [filterOption, setFilterOption] = useState<
    'ALL' | 'Connected' | 'Disconnected'
  >('ALL');
  const [displayedAssets, setDisplayedAssets] = useState<HealthScore[]>(assets);
  const [filteredAssetsState, setFilteredAssets] =
    useState<HealthScore[]>(assets);

  // Helper function to parse the selected month
  const parseSelectedMonth = (selectedMonth: string) => {
    const [monthName, year] = selectedMonth.split(' ');
    const monthIndex = new Date(
      Date.parse(`${monthName} 1, ${year}`)
    ).getMonth();
    return { monthIndex, year: Number(year) };
  };

  // Helper function to compare dates safely
  const compareDates = (dateA: string, dateB: string) => {
    const parsedDateA = new Date(dateA).getTime();
    const parsedDateB = new Date(dateB).getTime();

    if (isNaN(parsedDateA) && isNaN(parsedDateB)) return 0;
    if (isNaN(parsedDateA)) return 1;
    if (isNaN(parsedDateB)) return -1;

    return parsedDateB - parsedDateA;
  };

  // Helper function to compare numbers safely
  const compareNumbers = (numA: any, numB: any) => {
    const parsedNumA = Number(numA);
    const parsedNumB = Number(numB);

    if (isNaN(parsedNumA) && isNaN(parsedNumB)) return 0;
    if (isNaN(parsedNumA)) return 1;
    if (isNaN(parsedNumB)) return -1;

    return parsedNumB - parsedNumA;
  };

  // Filter by search query
  useEffect(() => {
    if (searchQuery) {
      setFilteredAssets((prevFiltered) =>
        prevFiltered.filter((asset) =>
          asset.asset_details.bumper_number
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery]);

  // Combined useEffect for filtering by connection status, sorting, and filtering by selected month
  useEffect(() => {
    let filtered = [...assets]; // Start with a fresh copy of the full list of assets

    // Filter by selected month
    if (selectedMonth) {
      const { monthIndex, year } = parseSelectedMonth(selectedMonth);
      filtered = filtered.filter((asset) => {
        const assetDate = new Date(
          asset.asset_details.squares_recommended_date
        );
        return (
          assetDate.getUTCMonth() === monthIndex &&
          assetDate.getUTCFullYear() === year
        );
      });
    }

    // Apply filter based on connection status
    if (filterOption === 'Connected') {
      filtered = filtered.filter((asset) => {
        const lastConnected = getLastConnected(
          asset.asset_details.bumper_number
        );
        return (
          lastConnected &&
          new Date(lastConnected).getTime() >= Date.now() - 24 * 60 * 60 * 1000
        );
      });
    } else if (filterOption === 'Disconnected') {
      filtered = filtered.filter((asset) => {
        const lastConnected = getLastConnected(
          asset.asset_details.bumper_number
        );
        return (
          !lastConnected ||
          new Date(lastConnected).getTime() < Date.now() - 24 * 60 * 60 * 1000
        );
      });
    }

    // Apply sorting based on the selected sort option
    const sorted = filtered.sort((a, b) => {
      switch (sortOption) {
        case 'Manufacturer Date':
          return compareDates(
            b.asset_details.manufacture_recommended_date,
            a.asset_details.manufacture_recommended_date
          );
        case 'Squares Date':
          return compareDates(
            b.asset_details.squares_recommended_date,
            a.asset_details.squares_recommended_date
          );
        case 'Health Score':
          return compareNumbers(
            b.asset_details.health_score,
            a.asset_details.health_score
          );
        default:
          return 0; // Default sorting
      }
    });

    // Update filtered assets state
    setFilteredAssets(sorted);
  }, [filterOption, sortOption, selectedMonth, assets]);

  const criticalityPriority = (criticality: string) => {
    switch (criticality) {
      case 'high':
        return 1; // Highest priority
      case 'med':
        return 2; // Medium priority
      case 'low':
        return 3; // Lowest priority
      default:
        return 4; // Default for undefined criticality
    }
  };

  const sortDataByCriticality = (data: ComponentDetails[]) => {
    return [...data].sort((a, b) => {
      const criticalityComparison =
        criticalityPriority(a.criticality) - criticalityPriority(b.criticality);

      if (criticalityComparison !== 0) {
        return criticalityComparison;
      }

      const dateA = new Date(a.manufacture_recommended_date);
      const dateB = new Date(b.manufacture_recommended_date);

      return dateA.getTime() - dateB.getTime(); // Older dates come first
    });
  };

  const getLastConnected = (id: string) => {
    const asset = filteredAssets.find((asset) => asset.item_id === id);
    return asset ? asset.lastConnected : null;
  };

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
    setArrowDirection((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortOption(event.target.value);
  };

  const handleFilterChange = (filter: 'ALL' | 'Connected' | 'Disconnected') => {
    setFilterOption(filter);
  };

  // Update displayed assets based on filtered and sorted assets
  useEffect(() => {
    setDisplayedAssets(filteredAssetsState);
  }, [filteredAssetsState]);

  return (
    <Box>
      {/* Toolbar with Search, Button Group, and Sort Dropdown */}
      <Box
        display="flex"
        justifyContent="space-between"
        gap={1}
        alignItems="center"
        p={1}
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.tableBackground
            : ThemePalette.light.tableBackground,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          border: `1px solid ${
            enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black
          }`,
          borderBottom: '0px',
        }}
      >
        {/* Search Bar */}
        <TextField
          variant="outlined"
          placeholder="Search for an asset"
          value={searchQuery}
          onChange={handleSearchChange}
          size="small"
          InputProps={{
            sx: {
              height: modalInputFieldStyle.height,
              minWidth: '131px',
              background: enableDarkTheme
                ? ThemePalette.dark.inputBackground
                : ThemePalette.light.inputBackground,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              border: `0.5px solid ${
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        {/* Button Group */}
        <ButtonGroup>
          <Button
            onClick={() => {
              handleFilterChange('ALL');
            }}
            style={{
              color:
                filterOption === 'ALL'
                  ? '#ffffff'
                  : enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              background:
                filterOption === 'ALL'
                  ? '#007bff'
                  : enableDarkTheme
                  ? ThemePalette.dark.inputBackground
                  : ThemePalette.light.inputBackground,
              border: `0.5px solid ${
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }`,
            }}
          >
            ALL
          </Button>
          <Button
            onClick={() => {
              handleFilterChange('Connected');
            }}
            style={{
              color:
                filterOption === 'Connected'
                  ? '#ffffff'
                  : enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              background:
                filterOption === 'Connected'
                  ? '#007bff'
                  : enableDarkTheme
                  ? ThemePalette.dark.inputBackground
                  : ThemePalette.light.inputBackground,
              border: `0.5px solid ${
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }`,
            }}
          >
            Live Data available
          </Button>
          <Button
            onClick={() => {
              handleFilterChange('Disconnected');
            }}
            style={{
              color:
                filterOption === 'Disconnected'
                  ? '#ffffff'
                  : enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              background:
                filterOption === 'Disconnected'
                  ? '#007bff'
                  : enableDarkTheme
                  ? ThemePalette.dark.inputBackground
                  : ThemePalette.light.inputBackground,
              border: `0.5px solid ${
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }`,
            }}
          >
            Live Data Unavailable
          </Button>
        </ButtonGroup>

        {/* Sort Dropdown */}
        <FormControl
          variant="outlined"
          size="small"
          sx={{
            minWidth: 180,
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.inputBackground
              : ThemePalette.light.inputBackground,
            borderRadius: '4px',
          }}
        >
          <InputLabel
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Sort by
          </InputLabel>
          <Select
            value={sortOption}
            onChange={handleSortChange}
            label="Sort by"
            sx={{
              background: 'transparent',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  background: enableDarkTheme
                    ? ThemePalette.dark.inputBackground
                    : ThemePalette.light.inputBackground,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  '& .MuiMenuItem-root': {
                    background: enableDarkTheme
                      ? ThemePalette.dark.inputBackground
                      : ThemePalette.light.inputBackground,
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    '&:hover': {
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.inputBackground
                        : ThemePalette.light.inputBackground,
                    },
                  },
                },
              },
            }}
          >
            <MenuItem
              value="Manufacturer Date"
              sx={{
                background: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Manufacturer Rec. Date{' '}
            </MenuItem>
            <MenuItem
              value="Squares Date"
              sx={{
                background: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Squares Rec. Date
            </MenuItem>
            <MenuItem
              value="Health Score"
              sx={{
                background: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Health Score
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Main Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Table
          style={{
            background: enableDarkTheme
              ? ThemePalette.dark.tableBackground
              : ThemePalette.light.tableBackground,
            border: `1px solid ${
              enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black
            }`,
            borderTop: '0px',
            borderBottom: '0px',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.tableHeader
                  : ThemePalette.light.tableHeader,
              }}
            >
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '23.67%',
                }}
              >
                Asset Details
              </TableCell>
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '10%',
                }}
              >
                Health Score
              </TableCell>
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '16.67%',
                }}
              >
                Age (Last Maintenance)
              </TableCell>
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '16.67%',
                }}
              >
                Manufacturer Rec. Date
              </TableCell>
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '16.67%',
                }}
              >
                Squares Rec. Date
              </TableCell>
              <TableCell
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  width: '14%',
                }}
                align="right"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedAssets.length > 0 ? (
              displayedAssets.map((asset, index) => (
                <React.Fragment key={asset.asset_details.bumper_number}>
                  <TableRow
                    sx={{
                      border: `1px solid ${
                        enableDarkTheme
                          ? ThemePalette.typography.grey
                          : ThemePalette.typography.black
                      }`,
                    }}
                  >
                    {/* Asset Details */}
                    <TableCell>
                      <Box display="flex" alignItems="center" gap={2}>
                        <GetIcons icon={assetImage} enableDarkTheme={true} />
                        <Box>
                          <Typography
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                            variant="body2"
                          >
                            {asset.asset_details.bumper_number} -
                          </Typography>
                          <Typography
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                            variant="body2"
                          >
                            {asset.asset_details.asset_type} -{' '}
                            {asset.asset_details.model}
                          </Typography>
                          <Typography
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                            }}
                            variant="body2"
                            fontSize={8}
                          >
                            {timeAgo(
                              getLastConnected(
                                asset.asset_details.bumper_number
                              )
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {/* Health Score */}
                    <TableCell
                      onClick={() => {
                        handleRowClick(index);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <HealthScorePie
                          enableDarkTheme={enableDarkTheme}
                          data={healthScorePieRange}
                          value={Number(asset.asset_details.health_score) * 100}
                          width={100}
                          height={100}
                          innerRadius={35}
                          outerRadius={45}
                          needleColor="#d0d000"
                          label={`${(
                            Number(asset.asset_details.health_score) * 100
                          ).toFixed(0)}%`}
                          arrowDirection={arrowDirection[index] ?? false}
                        />
                      </Box>
                    </TableCell>
                    {/* Age */}
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        >
                          <SVG name="engineLight" />
                        </Typography>
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                          variant="body1"
                        >
                          {Number(asset.asset_details.age_in_days) * 24} Hours
                        </Typography>
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.grey,
                          }}
                          variant="subtitle2"
                        >
                          <CalendarToday sx={{ fontSize: '12px' }} />{' '}
                          {new Date(
                            new Date().setDate(
                              new Date().getDate() -
                                Number(asset.asset_details.age_in_days)
                            )
                          ).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/* Manufacturer Rec. Date */}
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <CalendarToday
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.grey,
                          }}
                        />
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                          variant="body1"
                        >
                          {asset.asset_details.manufacture_recommended_date}
                        </Typography>
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.grey,
                          }}
                          variant="subtitle2"
                          color="grey"
                        >
                          {(() => {
                            const daysDifference = Math.ceil(
                              (new Date(
                                asset.asset_details.manufacture_recommended_date
                              ).getTime() -
                                new Date().getTime()) /
                                (1000 * 60 * 60 * 24)
                            );

                            if (daysDifference > 0) {
                              return `${daysDifference} Days from now`;
                            } else if (daysDifference < 0) {
                              return `${Math.abs(daysDifference)} Days overdue`;
                            } else {
                              return 'Immediate';
                            }
                          })()}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/* Squares Rec. Date */}
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <CalendarToday
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.grey,
                          }}
                        />
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                          variant="body1"
                        >
                          {asset.asset_details.squares_recommended_date}
                        </Typography>
                        <Typography
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.grey,
                          }}
                          variant="subtitle2"
                          color="grey"
                        >
                          {(() => {
                            const daysDifference = Math.ceil(
                              (new Date(
                                asset.asset_details.squares_recommended_date
                              ).getTime() -
                                new Date().getTime()) /
                                (1000 * 60 * 60 * 24)
                            );

                            if (daysDifference > 0) {
                              return `${daysDifference} Days from now`;
                            } else if (daysDifference < 0) {
                              return `${Math.abs(daysDifference)} Days overdue`;
                            } else {
                              return 'Immediate';
                            }
                          })()}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/* Action Button */}
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="outlined"
                        style={{
                          padding: '10px',
                          fontSize: '0.7rem',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          background: enableDarkTheme
                            ? ThemePalette.dark.tableBackground
                            : ThemePalette.light.tableBackground,
                          border: '1px solid #3666EC',
                        }}
                        onClick={() => {
                          onShowDetails(asset.asset_details.bumper_number);
                        }}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                  {/* Expanded Row for Asset Details */}
                  {expandedRow === index && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ padding: 0 }}>
                        <AssetDetailsTable
                          enableDarkTheme={enableDarkTheme}
                          details={sortDataByCriticality(
                            asset.component_details
                          )}
                          filteredAssets={assetsDetails}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  {assets.length === 0 ? (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body1"
                    >
                      Loading Assets ...
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                      variant="body1"
                    >
                      No Assets
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AssetTable;
