import {
  Box,
  Typography,
  Checkbox,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { ThemePalette } from 'mui.theme';
import { type ChangeEvent, useState, useEffect } from 'react';
import { Error } from '@mui/icons-material';
import DatePicker from 'react-datepicker';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { type EolDates } from 'types/survivalAnalysis.types';
import SurvivalAnalysisOverviewHandler from 'handlers/survivalAnalysisHandlers/survivalAnalysisOverview.handler';
import { getComponentFromEol } from '../Function/asset.helper.function';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { modalInputFieldStyle } from 'styles/global.css';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
  component?: any;
  handleModalSuccess: () => void;
}

export const SurvivalAnalysisEditModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme,
  component,
  handleModalSuccess,
}: Props) => {
  const survivalAnalysisHandler = new SurvivalAnalysisOverviewHandler();

  const [isValidForm, setFormValidity] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const [startDate, setStartDate] = useState(yesterday);
  const [startDateByDay, setStartDateByDay] = useState('0');
  const [startDateRadio, setStartDateRadio] = useState('byDate');
  const [newExpectedEol, setNewExpectedEol] = useState(component.eolDate);

  const handleDateChange = (range: any) => {
    if (range.getTime() !== startDate.getTime()) {
      setStartDate(range);
    }
  };
  const handleDateChangeByDay = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEolByDayValue =
      parseInt(event.target.value, 10) > 0
        ? parseInt(event.target.value, 10)
        : 0;
    setStartDateByDay(event.target.value);
    const day = newEolByDayValue;
    const dateOfOrder = new Date(component.eolDate);
    dateOfOrder.setDate(dateOfOrder.getDate() + day);
    setNewExpectedEol(dateOfOrder.toISOString().split('T')[0]);
    setStartDate(dateOfOrder);
  };

  function handleCheckBoxSelected(result: ChangeEvent<HTMLInputElement>) {
    setIsChecked(result.target.checked);
    setFormValidity(true);
  }
  const handleSuccess = async () => {
    if (component.eolDate) {
      const updatedEolDate = { ...component, eol: startDate }; // Create a copy and update the eol property
      await survivalAnalysisHandler.editEOL(updatedEolDate); // Await the promise
    }
    handleAbort();
    handleModalSuccess();
  };
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'Update',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      enableDarkTheme={enableDarkTheme}
    >
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {' '}
            <Box>
              <Typography
                fontStyle={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
                sx={{ paddingRight: '5px' }}
              >
                Current High Risk Date
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                fontStyle={{
                  color: '#EB5757',
                }}
                sx={{ paddingRight: '5px' }}
              >
                {component.eolDate}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Ensures the box takes the full height of the grid item
              }}
            >
              <DoubleArrowIcon
                sx={{ fontSize: '4rem', color: 'rgba(255, 255, 255, 0.5)' }}
              />{' '}
            </Box>
          </Grid>
          <Grid item xs={4}>
            {' '}
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={startDateRadio}
              onChange={(event) => {
                setStartDateRadio(event.target.value);
              }}
            >
              <FormControlLabel
                value="byDate"
                control={
                  <Radio
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    style={{ fontSize: '12px' }}
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    By Date
                  </Typography>
                }
              />
              <FormControlLabel
                value="byDay"
                control={
                  <Radio
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    style={{ fontSize: '12px' }}
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    By Day
                  </Typography>
                }
              />
            </RadioGroup>
            {startDateRadio === 'byDate' ? (
              <>
                <Typography
                  variant="body2"
                  fontStyle={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  sx={{ paddingLeft: '5px', paddingBottom: '12px' }}
                >
                  New High Risk Date{' '}
                </Typography>
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={handleDateChange}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  fontStyle={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  sx={{ paddingLeft: '5px', paddingBottom: '12px' }}
                >
                  New Eol by Day{' '}
                </Typography>
                <SWTextField
                  key="startDateByDay"
                  name="startDateByDay"
                  TextFieldProps={{
                    name: 'startDateByDay',
                    value: startDateByDay,
                    onChange: handleDateChangeByDay,
                    InputProps: {
                      sx: {
                        height: '27px',
                        minWidth: '70px',
                        maxWidth: '190px',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                      },
                    },
                  }}
                  enableDarkTheme={enableDarkTheme}
                />
                <Typography
                  variant="body2"
                  fontStyle={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.grey
                      : ThemePalette.typography.grey,
                  }}
                >
                  New Expected Date <br />
                  {newExpectedEol}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.grey,
              }}
              sx={{ paddingLeft: '5px', paddingBottom: '12px' }}
            >
              Installation Date: {component.date_of_orders}
            </Typography>{' '}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
              }}
            >
              <Error style={{ color: '#FFA403' }} />
              <Typography
                variant="body2"
                fontStyle={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
                sx={{ paddingLeft: '5px', paddingBottom: '12px' }}
              >
                Before Updating, please inspect the following:
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              sx={{ paddingLeft: '12px', paddingBottom: '12px' }}
            >
              Tread Life{' '}
            </Typography>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              sx={{ paddingLeft: '12px', paddingBottom: '12px' }}
            >
              Absence of punctures/gashes{' '}
            </Typography>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              sx={{ paddingLeft: '12px', paddingBottom: '12px' }}
            >
              Absence of retread delamination{' '}
            </Typography>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              sx={{ paddingLeft: '12px', paddingBottom: '12px' }}
            >
              Absence of broken belts{' '}
            </Typography>
            <Typography
              variant="body2"
              fontStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              sx={{ paddingLeft: '12px', paddingBottom: '12px' }}
            >
              Absence of any other major damage{' '}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <br />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'left',
        }}
      >
        <Checkbox
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.cornflowerBlue
              : ThemePalette.typography.primary,
            '&.Mui-checked': {
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
            },
            '&.Mui-disabled': {
              color: ThemePalette.typography.secondary,
            },
            paddingTop: '0',
          }}
          checked={isChecked}
          onChange={(e) => {
            handleCheckBoxSelected(e);
          }}
        />
        <Typography
          variant="body2"
          fontStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          sx={{ paddingLeft: '5px' }}
        >
          I have inspected the items mentioned above.{' '}
        </Typography>
      </Box>
    </InteractiveModal>
  );
};

export default SurvivalAnalysisEditModal;
