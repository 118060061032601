import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { Selector } from 'components/Form/Selectors/Selector';
import { ThemePalette } from 'mui.theme';
import { modalInputFieldStyle } from 'styles/global.css';
import SurvivalAnalysisCard from 'views/SurvivalAnalysis/Helper/Modal/SurvivalAnalysis.card';
import { useAppSelector } from 'store/hook';
import {
  type CumulativeOverview,
  type AssetsOverview,
  type ServiceView,
  type EolDates,
} from 'types/survivalAnalysis.types';
import OverviewModalDetails from './Overview-modal-details';
import { selectLatestTagData } from 'store/device.slice';
import { selectNotificationsData } from 'store/notification.slice';
import { useFetchDashboardAssetsData } from 'views/SurvivalAnalysis/Helper/Function/fetch.helper.function';
import {
  processAssetsWithLiveData,
  processDataAssets,
} from 'views/SurvivalAnalysis/Helper/Function/asset.helper.function';

export const OverviewModalAsset = ({
  enableDarkTheme,
  allAssets,
  assetsPerSubComponent,
  selectedAssetFromTimeLine,
  eolDates,
  onOpenInNewClick,
  handleModalSuccess,
}: {
  enableDarkTheme: boolean;
  allAssets: AssetsOverview[];
  assetsPerSubComponent: CumulativeOverview[];
  selectedAssetFromTimeLine?: string;
  eolDates: EolDates[];
  onOpenInNewClick: (itemId: string) => void;
  handleModalSuccess: () => void;
}) => {
  const fetchDashboardAssetsData = useFetchDashboardAssetsData();

  const [sortCriteria, setSortCriteria] = useState('0');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAssets, setFilteredAssets] = useState<ServiceView[]>([]);
  const [processedData, setProcessedData] = useState<ServiceView[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<ServiceView>();
  const { assets } = useAppSelector((state) => state.assetReducer);
  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);

  useEffect(() => {
    if (selectedAssetFromTimeLine) {
      const assetWithLiveData = processAssetsWithLiveData(
        assets,
        latestTagData,
        notificationsData
      );
      const processedData = processDataAssets(
        allAssets,
        assetWithLiveData,
        eolDates
      );
      const filtered = processedData.filter(
        (asset) => asset.item_id === selectedAssetFromTimeLine
      );
      setSelectedAsset(filtered[0]);
    }
    onOpenInNewClick('');
  }, [selectedAssetFromTimeLine]);

  type CategoryKey = 'eol' | 'high' | 'med' | 'low';

  const sortCriteriaMap: Record<number, CategoryKey> = {
    2: 'high',
    3: 'med',
    4: 'low',
  };

  useEffect(() => {
    const filterAndSortAssets = () => {
      // Ensure sortCriteria is treated as a number
      const categoryKey = sortCriteriaMap[Number(sortCriteria)];
      const filtered = processedData
        .filter((asset) =>
          asset.item_id.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
          // Using nullish coalescing operator to handle undefined or null values
          const aCategoryValue = a[categoryKey] ?? 0;
          const bCategoryValue = b[categoryKey] ?? 0;
          return bCategoryValue - aCategoryValue; // Sort descending
        });

      setFilteredAssets(filtered);
    };

    filterAndSortAssets();
  }, [searchTerm, sortCriteria]); // Depend on filteredAssets, sortCriteria, and searchTerm

  useEffect(() => {
    const assetWithLiveData = processAssetsWithLiveData(
      assets,
      latestTagData,
      notificationsData
    );
    const processedData = processDataAssets(
      allAssets,
      assetWithLiveData,
      eolDates
    );
    setProcessedData(processedData);
    setFilteredAssets(processedData);
  }, [allAssets]);

  const showDetails = (asset: ServiceView) => {
    setSelectedAsset(asset); // Set the selected asset
  };

  if (selectedAsset) {
    return (
      <OverviewModalDetails
        enableDarkTheme={enableDarkTheme}
        displayItems={selectedAsset}
        assets={allAssets}
        uniqueAssets={filteredAssets}
        onShowDetails={showDetails}
        assetsPerSubComponent={assetsPerSubComponent}
        fetchSquaresData={fetchDashboardAssetsData}
        detailType="Assets"
        eolDates={eolDates}
        handleModalSuccess={handleModalSuccess}
      />
    );
  }

  return (
    <>
      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            fontStyle={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
            sx={{ paddingRight: '5px' }}
          >
            All Assets
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            placeholder="Search for an asset"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            sx={{
              width: '400px',
            }} // You can set this to any width you prefer
            InputProps={{
              sx: {
                height: modalInputFieldStyle.height,
                minWidth: '131px',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },

              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.grey,
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Box
            sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
          >
            <Typography sx={{ marginRight: '10px' }} fontSize="14px">
              Sort By
            </Typography>

            <Selector
              enableDarkTheme={enableDarkTheme}
              minWidth="1px"
              value={parseInt(sortCriteria)}
              selectorOptions={[
                { id: '0', display: 'Not Sorted' },

                { id: '2', display: 'High Risk' },
                { id: '3', display: 'Medium Risk' },
                { id: '4', display: 'Low Risk' },
              ]}
              onChange={(event) => {
                const selectedValue = event.target.value as string;
                setSortCriteria(selectedValue);
              }}
            />
          </Box>
        </Box>
      </ToolbarContainer>

      <SurvivalAnalysisCard
        filteredAssets={filteredAssets}
        enableDarkTheme={enableDarkTheme}
        onShowDetails={showDetails}
        detailType="Assets"
      />
    </>
  );
};
