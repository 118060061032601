import { useAppSelector } from 'store/hook';
import { type Asset } from 'types/asset.types';
import { type DashboardSquares } from 'types/squares.type';
import {
  type CumulativeOverview,
  type AssetsOverview,
  type ServiceView,
  type AssetDetail,
  type EolDates,
} from 'types/survivalAnalysis.types';
import { processDataAssets } from './asset.helper.function';

export const transformWorkOrdersToTimeLine = (
  workOrders: any[],
  assets: Asset[],
  eolDates: EolDates[]
): any[] => {
  const mergedWorkOrder = mergeEolDatesIntoComponents(workOrders, eolDates);
  const groupedOrders = mergedWorkOrder.reduce<
    Record<string, AssetsOverview[]>
  >((acc, order) => {
    const date = new Date(order.eolDate);
    const dateKey = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    acc[dateKey].push(order);
    return acc;
  }, {});

  const services = Object.entries(groupedOrders).map(([dateKey, orders]) => {
    const [year, month, day] = dateKey.split('-').map(Number);
    const processedOrders = processDataAssets(orders, assets, eolDates);

    return {
      date: new Date(year, month, day),
      numberOfServices: processedOrders.length,
      services: processedOrders,
    };
  });

  return services;
};

export interface AssetDetails {
  total_cost: number;
  total_labour_cost: number;
  total_part_cost: number;
  total_hours: string;
}

export type AssetData = Record<string, AssetDetails>;

export interface MetricDetails {
  asset_id: string;
  total_cost: number;
  total_sum: number;
  percentageOfTotal: string;
  percentageFromAverage: string;
}

// Adjust the return type since we're now returning an array of MetricDetails
export function calculateFinancialMetrics(
  data: DashboardSquares[],
  assetId: string
): MetricDetails | undefined {
  // Calculate the sum of all total costs

  const totalSum = Object.values(data).reduce(
    (sum, asset) => Number(sum) + Number(asset.total_cost),
    0
  );

  // Calculate the average total cost
  const averageCost = totalSum / Object.keys(data).length;

  const metricsArray: MetricDetails[] = [];

  // Populate the array with metrics for each asset
  Object.entries(data).forEach(([id, assetDetails]) => {
    const totalCost = assetDetails.total_cost ? assetDetails.total_cost : 0;
    const percentageOfTotal = (totalCost / totalSum) * 100;
    const percentageFromAverage =
      ((totalCost - averageCost) / averageCost) * 100;

    metricsArray.push({
      asset_id: id,
      total_cost: totalCost,
      total_sum: totalSum,
      percentageOfTotal: percentageOfTotal.toFixed(2) + '%',
      percentageFromAverage: percentageFromAverage.toFixed(2) + '%',
    });
  });

  // Return either all metrics or just the specific one if an asset ID is provided
  if (assetId) {
    const specificMetric = metricsArray.find(
      (metric) => metric.asset_id === assetId
    );
    return specificMetric;
  }
}

export function filterByCategoryBySubCategory(
  data: CumulativeOverview[],
  component?: string,
  subComponent?: string
): CumulativeOverview[] {
  // If component or subComponent is not provided, return undefined instead of null
  return data.filter(
    (item) =>
      item.components_category === component &&
      item.sub_component_categories === subComponent
  );
}
export function filterByCategory(
  data: CumulativeOverview[],
  category?: string
) {
  return data.filter((item) => item.components_category === category);
}
export function filterBySubCategory(
  data: CumulativeOverview[],
  subCategory?: string
) {
  return data.filter((item) => item.sub_component_categories === subCategory);
}

export function generateGraphData(cumulativeOverview: CumulativeOverview[]) {
  const result = [];
  const dataMap: Record<number, number> = {};

  // Map the data for quick lookup
  cumulativeOverview.forEach((item: CumulativeOverview) => {
    dataMap[item.month] = item.cumulative_assets;
  });

  // Ensure month 0 exists, initializing it with 0 cumulative assets if not provided
  const initialCumulativeAssets = dataMap[0] !== undefined ? dataMap[0] : 0;
  result.push({ month: 0, cumulative_assets: initialCumulativeAssets });

  // Get the total number of months from the last entry in cumulativeOverview
  const totalMonths =
    cumulativeOverview[cumulativeOverview.length - 1]?.month ?? 0;

  // Update the first month based on dataMap if it exists
  let lastCumulativeAssets =
    dataMap[1] !== undefined ? dataMap[1] : initialCumulativeAssets;
  result.push({ month: 1, cumulative_assets: lastCumulativeAssets });

  // Iterate from month 2 to the total number of months
  for (let month = 2; month <= totalMonths; month++) {
    // Update lastCumulativeAssets if the current month exists in dataMap
    if (dataMap[month] !== undefined) {
      lastCumulativeAssets = dataMap[month];
    }
    result.push({ month, cumulative_assets: lastCumulativeAssets });
  }

  return result;
}

export function calculateMonthDifference(startDate: string, endDate: Date) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    (end.getFullYear() - start.getFullYear()) * 12 +
    end.getMonth() -
    start.getMonth()
  );
}

export const toDate = (
  value: string | number | undefined
): Date | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const date = new Date(value);
  return isNaN(date.getTime()) ? undefined : date;
};

// Define an interface for each category entry
interface CategoryMappingEntry {
  color: string;
  text: string;
}

// Define the full category mapping type using Record
type CategoryMapping = Record<string, CategoryMappingEntry>;

// Define the actual category mapping
export const categoryMapping: CategoryMapping = {
  high: { color: '#cc4b37', text: 'High Risk' },
  med: { color: '#d1b200', text: 'Medium Risk' },
  low: { color: '#6ba583', text: 'Low Risk' },
  eol: { color: '#cc4b37', text: 'High Risk' },
  // eol: { color: 'rgba(255, 0, 0, 0.5)', text: 'EOL' },
};

export const riskDays = {
  high: { days: 61, text: 'High Risk' },
  med: { days: 59, text: 'Medium Risk' },
  low: { days: 44, text: 'Low Risk' },
};

export function sortOrders(orders: any[], sortOrder: string) {
  // Define the sorting order arrays
  const orderLowToEol = ['low', 'med', 'high', 'eol'];
  const orderEolToLow = ['eol', 'high', 'med', 'low'];

  // Determine which order array to use based on the sortOrder parameter
  const orderArray =
    sortOrder.toLowerCase() === 'low-eol' ? orderLowToEol : orderEolToLow;

  // Sort the orders array based on the orderArray
  return orders.sort((a, b) => {
    return (
      orderArray.indexOf(a.work_order_age_category) -
      orderArray.indexOf(b.work_order_age_category)
    );
  });
}

export function formatAgeInDays(ageInDays: number): string {
  if (ageInDays < 7) {
    return `${ageInDays} day(s)`;
  } else if (ageInDays < 30) {
    const weeks = Math.floor(ageInDays / 7);
    const days = ageInDays % 7;
    return `${weeks} week(s)${days > 0 ? ` and ${days} day(s)` : ''}`;
  } else {
    const months = Math.floor(ageInDays / 30);
    const days = ageInDays % 30;
    return `${months} month(s)${days > 0 ? ` and ${days} day(s)` : ''}`;
  }
}

export function formatToDollars(value: number | undefined) {
  // Convert number to string with commas for thousands separator
  if (!value) {
    return '';
  }
  const formattedValue = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `$${formattedValue}`;
}

export const formatToUSD = (amount: number, type: string) => {
  const formatAmount = (num: number) => {
    // Check if the number has decimal places
    const [integerPart, decimalPart] = num.toString().split('.');

    // If there's no decimal part or the decimal is all zeros, return the integer part
    if (!decimalPart || Number(decimalPart) === 0) {
      return integerPart;
    }

    // Remove unnecessary trailing zeros from the decimal part
    return num % 1 === 0
      ? num.toFixed(0)
      : parseFloat(num.toFixed(2)).toString();
  };

  if (amount >= 1_000_000) {
    return `${formatAmount(amount / 1_000_000)}M ${type}`;
  } else if (amount >= 1_000) {
    return `${formatAmount(amount / 1_000)}K ${type}`;
  } else {
    return `${formatAmount(amount)} ${type}`;
  }
};

export const getNumericValue = (percentageString: string | undefined) => {
  if (percentageString) {
    return parseFloat(percentageString.replace('%', ''));
  }
  return 0; // Default to 0 if the value is not available
};

export const addDaysToDate = (dateString: string, days: number): string => {
  const date = new Date(dateString);
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  const year = result.getFullYear();
  const month = String(result.getMonth() + 1).padStart(2, '0');
  const day = String(result.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getMedianWorkOrderAge = (dataSet: Set<any>): number => {
  // Convert the set to an array
  const dataArray = Array.from(dataSet);

  // Extract the work_order_age_in_days values
  const workOrderAges = dataArray.map((item) => item.work_order_age_in_days);

  // Sort the ages in ascending order
  workOrderAges.sort((a, b) => a - b);

  // Calculate the median
  const mid = Math.floor(workOrderAges.length / 2);

  if (workOrderAges.length % 2 === 0) {
    return (Number(workOrderAges[mid - 1]) + Number(workOrderAges[mid])) / 2;
  } else {
    return workOrderAges[mid];
  }
};

export const mergeEolDatesIntoComponents = (
  components: any[],
  eolDates: EolDates[]
): any[] => {
  return components.map((component) => {
    // Find matching eolDate
    const matchingEolDate = eolDates.find(
      (eolDate) =>
        eolDate.asset_id === component.asset_id &&
        eolDate.components_category === component.components_category &&
        eolDate.sub_components_category === component.sub_component
    );

    const eolDate = matchingEolDate
      ? matchingEolDate.eol
      : addDaysToDate(component.date_of_orders, riskDays.high.days);
    return {
      ...component,
      eolDate,
    };
  });
};
