import { Box, Typography, LinearProgress } from '@mui/material';
import { ThemePalette } from 'mui.theme';

// Custom linear progress styles
const linearProgressStyles = {
  height: 10,
  borderRadius: 5,
  backgroundColor: '#F9A405',
};
// Function to calculate the position of the arrow
const getArrowPosition = (value: any) => {
  let valueToUse = value;
  if (value === 100) {
    valueToUse = 99.8;
  }
  const position = (valueToUse / 100) * 100; // Calculate percentage
  return `calc(${position}% - 8px)`; // Adjust the position for the arrow
};

export const ProgressBarWithArrow = ({
  value,
  enableDarkTheme,
  disabled = false,
}: any) => {
  return (
    <Box position="relative">
      <Typography
        variant="body2"
        color="textSecondary"
        style={{
          position: 'absolute',
          top: -10,
          zIndex: 1,
          left: getArrowPosition(value),
          transform: 'rotate(180deg)', // Rotate the arrow to point downwards
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          opacity: disabled ? 0.3 : 1,
        }}
      >
        ▲
      </Typography>
      <LinearProgress
        variant="determinate"
        value={100}
        sx={{
          ...linearProgressStyles,
          opacity: disabled ? 0.3 : 1,
          '& .MuiLinearProgress-bar': {
            borderRadius: 5,
            background: `linear-gradient(to right, #eb5757 0%, #eb5757 33%, #fd7f34 33%, #fd7f34 66%, #228b22 66%, #228b22 100%)`,
          },
        }}
      />
    </Box>
  );
};
