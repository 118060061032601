import { useAuth0 } from '@auth0/auth0-react';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import UserHandler from 'handlers/internal.user.handler';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { useEffect, useMemo, useState } from 'react';
import {
  selectAssetsWithExcessiveIdling,
  selectAssociatedAssets,
  selectAssociatedFilteredAssets,
} from 'store/asset.slice';
import { selectLatestTagData } from 'store/device.slice';
import { useAppSelector } from 'store/hook';
import { AssetTable } from './AssetDisplay';
import { AssetCompositionPieChart } from './components/AssetCompositionPieChart/AssetCompositionPieChart';
import {
  addLiveDataToAssets,
  assetTypeMappings,
} from './helpers/asset.helpers';
import { selectNotificationsData } from 'store/notification.slice';
import { ExcessiveIdlingAssetCompositionPieChart } from './components/ExcessiveIdlingAssetCompositionPieChart/ExcessiveIdlingAssetCompositionPieChart';
import useCustomEffect from './helpers/pieChart2Logic';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { isDarkTheme } from 'utils/theme';
import { FleetOverviewV2GridBox } from './FleetOverviewV2/FleetOverviewV2Grid';
import { type Asset } from 'types/asset.types';

export const FleetOverview = () => {
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const customerId = useAppSelector((state) => state.persistedReducer).customer
    .id;
  const [pieChartValue, setPieChartValue] = useState({
    clickState: true,
    val: '',
  });
  const [oldVal, setOldVal] = useState('');
  const userHandler = new UserHandler();

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated || !accessToken || !customerId) return;

      await userHandler.userInfoSync();
    };

    void fetchData();
  }, [isAuthenticated, accessToken, customerId]);

  const associatedFilteredAssets = useAppSelector(
    selectAssociatedFilteredAssets
  );
  const unfilteredAssets = useAppSelector(selectAssociatedAssets);
  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);
  const {
    assets,
    assetsLoaded,
    assetOverView: assetOverview,
    isLoadingAllAssets,
  } = useAppSelector((state) => state.assetReducer);

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [shouldDraw, setShouldDraw] = useState<boolean>(false);

  // filter associatedFilteredAssets here with values from the second pie chart
  const excessiveIdlingAssetsData = useAppSelector(
    selectAssetsWithExcessiveIdling
  );

  const updateFilterWithPiechart2 = (clickState: boolean, val: string) => {
    setPieChartValue({ clickState, val });
  };

  const [newArr, setNewArr] = useState(associatedFilteredAssets);

  useCustomEffect({
    pieChartValue,
    associatedFilteredAssets,
    oldVal,
    excessiveIdlingAssetsData,
    setNewArr,
    setOldVal,
  });

  /**
   * NOTE: Leave this in for debugging purposes
   * because we're still working on the live process of
   * retrieving tags
   */
  console.log(`DEBUG latestTagData ${new Date().toISOString()}`, {
    latestTagData,
  });

  // add live data to the asset
  const filteredAssets = useMemo(() => {
    return addLiveDataToAssets(newArr, latestTagData, notificationsData);
  }, [newArr, latestTagData]);

  useEffect(() => {
    const shouldRenderTable = filteredAssets.some((asset) => {
      return asset.device?.daysBehind !== undefined;
    });
    setShouldDraw(shouldRenderTable);
  }, [filteredAssets]);

  /**
   * This is used to test the new dashboard behind this value.
   * To be removed when we are satisfied with tests
   * LEAVE IN in case we want a roll back
   */
  const shouldShowNewDashboard = true;

  const hasNoDozer =
    assets.filter(
      (asset: Asset) => assetTypeMappings[asset.assetType] === 'Others'
    ).length === 0;

  return (
    <>
      {assetsLoaded && (
        <>
          {shouldShowNewDashboard ? (
            <FleetOverviewV2GridBox
              enableDarkTheme={enableDarkTheme}
              assetOverview={assetOverview}
              hasNoDozer={hasNoDozer}
            />
          ) : (
            <FlexBox sx={{ gap: '10px' }}>
              <AssetCompositionPieChart
                filteredAssets={newArr}
                unfilteredAssets={unfilteredAssets}
                enableDarkTheme={enableDarkTheme}
              />

              <ExcessiveIdlingAssetCompositionPieChart
                filteredAssets={newArr}
                unfilteredAssets={unfilteredAssets}
                updateFilter={updateFilterWithPiechart2}
                enableDarkTheme={enableDarkTheme}
              />
            </FlexBox>
          )}
        </>
      )}
      {filteredAssets.length <= 0 && assetsLoaded ? (
        <NoChartData
          message="No assets selected for display"
          enableDarkTheme={enableDarkTheme}
        />
      ) : !assetsLoaded && !shouldDraw ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : shouldDraw && filteredAssets.length > 0 ? (
        <Base enableDarkTheme={enableDarkTheme}>
          <AssetTable
            filteredAssets={filteredAssets}
            unfilteredAssets={unfilteredAssets}
            enableDarkTheme={enableDarkTheme}
            shouldShowNewDashboard={shouldShowNewDashboard}
            isLoadingAllAssets={isLoadingAllAssets}
          />
        </Base>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      )}
    </>
  );
};
