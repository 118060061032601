import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Typography, Grid } from '@mui/material';
import { useAppSelector } from 'store/hook';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { ComponentsTreeChart } from './Components/TreeChart';
import { ComponentsPieChart } from './Components/PieChart';
import { ComponentsLineChart } from './Components/LineChart';
import { Base } from 'components/Containers/BaseContainer';
import {
  extractWorkOrderData,
  getAssetsByKey,
  getFirstAttribute,
  extractSelectedAssetsTimesSeries,
  type AssetData,
} from './utils';
import { ThemePalette } from 'mui.theme';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';

export const AssetsViewV2 = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const squaresHandler = new SquaresDashboardHandler();
  const { assetDashboard: assetDashboardData } = useAppSelector(
    (state) => state.squaresDashboardReducer
  );

  interface WorkOrderItem {
    name: string;
    totalCost: number;
    totalHours: string;
    totalLabourCost: string;
    totalPartCost: string;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [workOrderData, setWorkOrderData] = useState<AssetData[]>([]);

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );
  let initialStartDate = new Date(
    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
  );
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  const [selectedCell, setSelectedCell] = useState<string[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedAssetsCostType, setSelectedAssetsCostType] = useState<
    string[]
  >(
    ['total_labour_cost', 'total_part_cost'] // Initialize with all available types
  );

  const handleTreeCellClick = (name: string) => {
    let newSelectedCell;

    if (selectedCell.includes(name)) {
      // Remove name if it's already in the array
      newSelectedCell = selectedCell.filter((cellName) => cellName !== name);
    } else {
      // Add name to the array if it's not already included
      newSelectedCell = [...selectedCell, name];
    }

    setSelectedCell(newSelectedCell);
    setSelectedAssets(newSelectedCell);
    // Clear assets if no cell is selected
    if (newSelectedCell.length <= 0) {
      setSelectedAssets([]);
    }
  };

  const handlePieClick = (data: any) => {
    const name = data.name;
    const updatedClickedCells = [...selectedAssetsCostType];
    if (updatedClickedCells.includes(name)) {
      // Remove the clicked name if it's already selected
      const indexToRemove = updatedClickedCells.indexOf(name);
      updatedClickedCells.splice(indexToRemove, 1);
    } else {
      // Add the clicked name if it's not already selected
      updatedClickedCells.push(name);
    }
    setSelectedAssetsCostType(updatedClickedCells);
  };

  const pieChartDataByKey = getAssetsByKey(
    assetDashboardData.assets,
    selectedCell
  );
  const selectedAssetsTimesSeries = extractSelectedAssetsTimesSeries(
    assetDashboardData.timeseries,
    selectedCell,
    selectedAssetsCostType
  );

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setIsLoading(true);
      setSelectedAssets([]);
      void squaresHandler.getDashboardAssetsData(
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const highestKey = getFirstAttribute(assetDashboardData.assets);
    setSelectedCell(highestKey);
    setWorkOrderData(extractWorkOrderData(assetDashboardData.assets));
    setIsLoading(false);
  }, [assetDashboardData]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingLeft: '40%',
          paddingTop: '10px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            paddingRight: '10px',
            fontSize: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Assets Total Cost
        </Typography>
        <DatePicker
          showIcon
          selected={selectedStartDate}
          onChange={handleDateChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={new Date(new Date().getTime())}
          selectsRange
        />
      </Box>
      {isLoading ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Waiting For Assets
          <LoadingDots enableDarkTheme={enableDarkTheme} />{' '}
        </Typography>
      ) : (
        <>
          {workOrderData.length <= 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                paddingTop: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {' '}
              No Data, Please Select a different Date Range
            </Typography>
          ) : (
            <>
              <Base
                sx={{
                  padding: '20px 20px 40px 20px',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.toolBarBackground
                    : ThemePalette.light.toolBarBackground,
                }}
              >
                <Box
                  sx={{
                    height: '400px',
                    maxHeight: '400px',
                  }}
                >
                  <ComponentsTreeChart
                    selectedCell={selectedCell}
                    handleCellClick={handleTreeCellClick}
                    data={workOrderData}
                    enableDarkTheme={enableDarkTheme}
                  />
                </Box>
              </Base>
              <Grid
                sx={{
                  display: 'flex',
                  marginLeft: '0px',
                  maxWidth: '100%',
                  paddingBottom: '30px',
                }}
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginLeft: '0px',
                    paddingBottom: '30px',
                  }}
                >
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Base
                      sx={{
                        height: '400px',
                        maxHeight: '400px',
                        backgroundColor: enableDarkTheme
                          ? ThemePalette.dark.toolBarBackground
                          : ThemePalette.light.toolBarBackground,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                            paddingTop: '30px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        >
                          {`Labour Cost vs Part Cost`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <ComponentsPieChart
                          data={pieChartDataByKey}
                          handlePieClick={handlePieClick}
                          selectedAssetsCostType={selectedAssetsCostType}
                          enableDarkTheme={enableDarkTheme}
                        />
                      </Box>
                    </Base>
                  </Grid>
                  <Grid item lg={8} md={12} sm={12} xs={12}>
                    <Base
                      sx={{
                        height: '400px',
                        maxHeight: '400px',
                        backgroundColor: enableDarkTheme
                          ? ThemePalette.dark.toolBarBackground
                          : ThemePalette.light.toolBarBackground,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                            paddingTop: '30px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                          }}
                        >
                          {`Assets time series Chart`}
                        </Typography>
                      </Box>

                      {selectedAssetsCostType.length === 0 ? (
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                            paddingTop: '10px',
                          }}
                        >
                          Please select an asset from the pie chart.
                        </Typography>
                      ) : (
                        <ComponentsLineChart
                          data={selectedAssetsTimesSeries}
                          selectedAssetsCostType={selectedAssetsCostType}
                          enableDarkTheme={enableDarkTheme}
                        />
                      )}
                    </Base>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
};
