import { Grid, Typography, Select, MenuItem, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  enableDarkTheme: boolean;
  options: any;
  selected: string;
  setSelected: any;
  minWidth?: string;
}

const ProductionSelector = ({
  enableDarkTheme,
  options,
  selected,
  setSelected,
  minWidth = '120px',
}: Props) => {
  return (
    <Select
      value={selected}
      onChange={(event) => {
        setSelected(event.target.value);
      }}
      sx={{
        minWidth,
        height: '30px',
        fontSize: '11px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.cartesianGrid
          : ThemePalette.light.menuOptions,
        color: enableDarkTheme
          ? ThemePalette.typography.lightGrey
          : ThemePalette.typography.black,
        '& .MuiSelect-icon': {
          color: enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.black,
        },
        '& fieldset': {
          borderColor: enableDarkTheme ? '#2F445D' : '#2F445D',
        },
        ...(enableDarkTheme && {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#2F445D', // <------------------ outline-color by default
            },
          },
        }),
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.menuOptions
              : ThemePalette.light.menuOptions,
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
            fontSize: '12px',
          },
        },
      }}
    >
      {options.map((option: any) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            fontSize: '12px',
          }}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ProductionSelector;
