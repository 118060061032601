import { LinearProgress, Paper, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { useState } from 'react';
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
} from 'recharts';
import { useAppSelector } from 'store/hook';
import { type GeoTrip } from 'types/geo.types';
import { isDarkTheme } from 'utils/theme';
import ChartTooltip from './ChartTooltip';
import { getTripTime } from '../utils';

interface PropTypes {
  data: GeoTrip[];
  handleSelectTrip: (trip: number) => void;
  loading: boolean;
}

const TripsAreaChart = ({ data, handleSelectTrip, loading }: PropTypes) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  // remove this method and revert to data if/when this is sorted from the data team (https://symboticware.atlassian.net/browse/OO4-1073)
  const processedData = data.map((item) => {
    const { startTime, endTime } = getTripTime([
      item.unloaded_trip_start_ts,
      item.loaded_trip_start_ts,
      item.unloaded_trip_end_ts,
      item.loaded_trip_end_ts,
      item.loading_end_ts,
      item.loading_start_ts,
      item.unloading_end_ts,
      item.unloading_start_ts,
    ]);
    return {
      ...item,
      startTime,
      endTime,
    };
  });

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return loading ? (
    <LinearProgress />
  ) : (
    <Paper
      style={{
        width: '100%',
        height: '500px',
        overflow: 'scroll',
        padding: '0 0 0 10px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.modalBackground
          : ThemePalette.light.menuOptions,
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <AreaChart
        width={1600}
        height={450}
        data={processedData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 15,
        }}
        style={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#26364C"
        />
        <XAxis
          dataKey="trip"
          name="Trip Start Time"
          onClick={(event: any) => {
            handleSelectTrip(event.index);
          }}
          label={{
            value: 'Trips',
            position: 'insideBottom',
            offset: -10,
            fill: enableDarkTheme ? '#878A99' : 'blue',
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
          tick={({
            x,
            y,
            payload,
            index,
          }: {
            x: number;
            y: number;
            payload: any;
            index: number;
          }) => (
            <text
              x={x}
              y={y + 10}
              textAnchor="middle"
              fill={
                hoveredIndex === index
                  ? 'red'
                  : enableDarkTheme
                  ? '#878A99'
                  : 'blue'
              }
              fontWeight="bold"
              fontFamily="Arial"
              fontSize="12px"
              onMouseEnter={() => {
                handleMouseEnter(index);
              }}
              onMouseLeave={handleMouseLeave}
              style={{ cursor: 'pointer' }}
            >
              Trip {payload.value}
            </text>
          )}
        />
        <YAxis
          label={{
            value: 'Time Taken ( in secs )',
            angle: -90,
            offset: -10,
            position: 'insideLeft',
            fill: enableDarkTheme ? '#878A99' : 'blue',
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
          tick={{
            fill: enableDarkTheme ? '#878A99' : 'grey',
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
        />
        <Tooltip content={<ChartTooltip />} />
        <Area
          type="monotone"
          dataKey="trip"
          name="Trip num"
          stackId="1"
          stroke={enableDarkTheme ? '#ffffff' : '000000'}
          fill="#000000"
        />
        <Area
          type="monotone"
          dataKey="startTime"
          name="Trip Start Time"
          stackId="1"
          stroke={enableDarkTheme ? '#ffffff' : '000000'}
          fill="#000000"
        />
        <Area
          type="monotone"
          dataKey="loading_duration_sec"
          name="Loading Time (in seconds)"
          stackId="1"
          stroke="#EB5757"
          fill="#EB5757"
        />
        <Area
          type="monotone"
          dataKey="unloading_duration_sec"
          name="Unloading Time (in seconds)"
          stackId="1"
          stroke="#009161"
          fill="#009161"
        />
        <Area
          type="monotone"
          dataKey="loaded_trip_duration_sec"
          name="Loaded Trip Time (in seconds)"
          stackId="1"
          stroke="#FFA403"
          fill="#FFA403"
        />
        <Area
          type="monotone"
          dataKey="unloaded_trip_duration_sec"
          name="Unloaded Trip Time (in seconds)"
          stackId="1"
          stroke="#497BFA"
          fill="#497BFA"
        />
        <Area
          type="monotone"
          dataKey="endTime"
          name="Trip End Time"
          stackId="1"
          stroke={enableDarkTheme ? '#ffffff' : '000000'}
          fill="#000000"
        />
        <Legend
          verticalAlign="top"
          iconType="line"
          style={{
            padding: '16px',
          }}
        />
      </AreaChart>
      <Typography
        variant="subtitle2"
        sx={{
          paddingLeft: '60px',
          fontWeight: 'normal',
          textAlign: 'left',
          marginBottom: '20px',
        }}
      >
        Click on any trip to plot the GPS coordinates for a certain trip
      </Typography>
    </Paper>
  );
};

export default TripsAreaChart;
