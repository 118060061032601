import type { fleetDataSummary, productionAsset } from 'types/production.types';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { truncateTo2dp } from 'utils/helpers/general';
import {
  convertMetricTonsToImperialTons,
  convertLitersToGallons,
} from 'utils/helpers/unitSystem';

export function getHaulerSummary(arg: fleetDataSummary) {
  return [
    {
      name: 'Dump',
      time: `${truncateTo2dp(convertSeconds(arg.haul_truck_dump ?? 0).value)} ${
        convertSeconds(arg.haul_truck_dump ?? 0).unit
      }`,
      value: arg.haul_truck_dump ?? 0,
      direction: 'up',
      color: '#FF0068',
    },
    {
      name: 'Loaded Stop',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_loaded_stop ?? 0).value
      )} ${convertSeconds(arg.haul_truck_loaded_stop ?? 0).unit}`,
      value: arg.haul_truck_loaded_stop ?? 0,
      direction: 'up',
      color: '#A776FF',
    },
    {
      name: 'Travel Empty',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_travel_empty ?? 0).value
      )} ${convertSeconds(arg.haul_truck_travel_empty ?? 0).unit}`,
      value: arg.haul_truck_travel_empty ?? 0,
      direction: 'up',
      color: '#165BAA',
    },
    {
      name: 'Travel Loaded',
      time: `${truncateTo2dp(
        convertSeconds(arg.haul_truck_travel_loaded ?? 0).value
      )} ${convertSeconds(arg.haul_truck_travel_loaded ?? 0).unit}`,
      value: arg.haul_truck_travel_loaded ?? 0,
      direction: 'up',
      color: '#FF9C2C',
    },
  ];
}

export function getLoaderSummary(arg: fleetDataSummary) {
  return [
    {
      name: 'Dig',
      time: `${0 / 60} Mins`,
      value: 0,
      direction: 'up',
      color: '#A776FF',
    },
    {
      name: 'Dump',
      time: `${truncateTo2dp((arg.loader_dump ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_dump ?? 0,
      direction: 'up',
      color: '#FF0068',
    },
    {
      name: 'Travel Empty',
      time: `${truncateTo2dp((arg.loader_travel_empty ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_travel_empty ?? 0,
      direction: 'up',
      color: '#165BAA',
    },
    {
      name: 'Travel Loaded',
      time: `${truncateTo2dp((arg.loader_travel_loaded ?? 0 ?? 0) / 60)} Mins`,
      value: arg.loader_travel_loaded ?? 0,
      direction: 'up',
      color: '#FF9C2C',
    },
  ];
}

export function getProductionAssetsByTypeMapping(
  assets: productionAsset[],
  type: string
) {
  return assets.filter(
    (asset: productionAsset) => assetTypeMappings[asset.asset_type] === type
  );
}

export function getAssetChartDataSource(selectedSource: string) {
  if (selectedSource === 'Production') {
    return 'productionValue';
  }

  if (selectedSource === 'Utilization') {
    return 'utilization_by_whole_day';
  }

  if (selectedSource === 'Idling') {
    return 'idling_duration';
  }

  if (selectedSource === 'Fuel') {
    return 'fuel_consumption_l';
  }

  return 'max_load_ton';
}

export function getFleetProduction(selectedSource: string) {
  if (selectedSource === 'Last 7 Days') {
    return 'last_7_days';
  }

  if (selectedSource === 'Last 14 Days') {
    return 'last_14_days';
  }

  if (selectedSource === 'Last 4 Weeks') {
    return 'last_1_month';
  }

  if (selectedSource === 'Last 6 Months') {
    return 'last_6_months';
  }

  return 'last_7_days';
}

export function convertSeconds(seconds: number) {
  const units = [
    { name: 'year', seconds: 31536000 },
    { name: 'week', seconds: 604800 },
    { name: 'day', seconds: 86400 },
    { name: 'hour', seconds: 3600 },
    { name: 'minute', seconds: 60 },
    { name: 'second', seconds: 1 },
  ];

  for (const unit of units) {
    if (seconds >= unit.seconds || unit.name === 'second') {
      const value = seconds / unit.seconds;
      return {
        value: parseFloat(value.toFixed(6)),
        unit: unit.name + (value !== 1 ? 's' : ''),
      };
    }
  }

  return { value: 0, unit: 'seconds' };
}

export function getConvertionMethod(type: string, unitSystem: string) {
  if (unitSystem === 'imperial') {
    if (type === 'Production') {
      return convertMetricTonsToImperialTons;
    }

    if (type === 'Fuel') {
      return convertLitersToGallons;
    }
  }
}
