import { Grid, Typography, Box } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { ThemePalette } from 'mui.theme';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { SVG } from 'components/Asset/SVG';
import type { productionAsset } from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { convertSeconds } from '../../utils';
import { convertMetricTonsToImperialTons } from 'utils/helpers/unitSystem';

interface Props {
  enableDarkTheme: boolean;
  data: productionAsset;
  unitSystem: string;
}

const AssetItemDetails = ({ enableDarkTheme, data, unitSystem }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SVG
        name={mapVehiclesAssets(data.asset_type)}
        style={{
          fill: '#E2C171',
          height: '30px',
          width: '60px',
          paddingRight: '10px',
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '13px',
              }}
            >
              {data.make} - {data.model}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PowerSettingsNewIcon
                sx={{
                  color: data.device === 'aa' ? '#00C853' : '#E63946',
                  marginRight: '8px',
                  fontSize: '12px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {data.make} - {data.model}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Production
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(
                unitSystem === 'imperial'
                  ? convertMetricTonsToImperialTons(
                      data.max_load_sum
                    ).toString()
                  : data.max_load_sum.toString()
              )}
              {unitSystem === 'imperial' ? ' lbs' : ' Tons'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Loads / Day
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(data.avg_max_load_per_day.toString())}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Cycle Time
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(
                convertSeconds(data.total_cycle_count).value.toString()
              )}{' '}
              {convertSeconds(data.total_cycle_count).unit}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
              }}
            >
              Pass Count / Cycle
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {convertToTwoDecimalPlaces(
                convertSeconds(data.total_duration_sum).value.toString()
              )}{' '}
              {convertSeconds(data.total_duration_sum).unit}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssetItemDetails;
