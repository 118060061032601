import { Grid, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  unitSystem: string;
}

const data = [
  { name: 'Loaded Trip', value: 250, fill: '#FFA403' }, // Orange
  { name: 'Unloaded Trip', value: 150, fill: '#497BFA' }, // Blue
  { name: 'Loading', value: 50, fill: '#EB5757' }, // Red
  { name: 'Unloading', value: 100, fill: '#029161' }, // Green
];

const HaulerFuelConsumption = ({
  enableDarkTheme,
  title,
  isLoading,
  unitSystem,
}: Props) => {
  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Box
              sx={{
                marginTop: '10px',
              }}
            >
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    layout="vertical"
                    data={data}
                    margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
                    barSize={30}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={ThemePalette.dark.cartesianGrid}
                      vertical={false}
                    />
                    <XAxis
                      type="number"
                      tick={{ fill: '#8884d8' }}
                      label={{
                        value: `Fuel Consumed(${
                          unitSystem === 'imperial' ? 'gallons' : 'liters'
                        })`,
                        fontSize: 10,
                        position: 'bottom',
                        offset: 0,
                      }}
                    />
                    <YAxis
                      type="category"
                      dataKey="name"
                      tick={{
                        fill: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                        fontSize: '11px',
                      }}
                    />
                    <Tooltip
                      cursor={{ fill: '#1B2B4A' }}
                      wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                      }}
                      itemStyle={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                      }}
                    />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </FlexBox>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default HaulerFuelConsumption;
