import { Grid, Typography, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { ThemePalette } from 'mui.theme';
import { ProgressBarWithArrow } from 'views/FleetOverview/FleetOverviewV2/ProgressBarWithArrow';

interface Props {
  enableDarkTheme: boolean;
  analytics: any;
  isLoading: boolean;
}

const CardAnalytics = ({ enableDarkTheme, analytics, isLoading }: Props) => {
  return (
    <Box
      sx={{
        height: '130px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #2F445D',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      {isLoading ? (
        <LinearProgress
          color="secondary"
          sx={{
            marginTop: '65px',
          }}
        />
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: '13px',
              }}
            >
              {analytics.title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {analytics.hasWarning ? (
                <>
                  <WarningIcon
                    sx={{
                      color: '#FFB402',
                      marginRight: '4px',
                      fontSize: '15px',
                    }}
                  />
                  <Typography variant="body1">
                    {analytics.warningCount}
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Box>
          </Grid>

          <Grid container alignItems="center">
            <Grid item lg={12} sm={12}>
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: '30px',
                }}
              >
                {analytics.pointingUp ? (
                  <CallMadeIcon
                    sx={{
                      color: analytics.isGreen ? '#029161' : '#EB5757',
                      fontSize: '30px',
                    }}
                  />
                ) : (
                  <CallReceivedIcon
                    sx={{
                      color: analytics.isGreen ? '#029161' : '#EB5757',
                      fontSize: '30px',
                    }}
                  />
                )}
                {analytics.value}
                {analytics.unit ? (
                  <span
                    style={{
                      fontSize: '13px',
                      paddingLeft: '5px',
                      color: enableDarkTheme
                        ? '#8491A0'
                        : ThemePalette.typography.black,
                    }}
                  >
                    {analytics.unit}
                  </span>
                ) : (
                  ''
                )}
              </Typography>
            </Grid>

            {/* <Grid item lg={6} sm={6}>
          <Box
            sx={{
              height: '40px',
              backgroundImage: `linear-gradient(to top, ${
                analytics.isGreen ? '#092E44' : '#262944'
              }, rgba(255, 0, 0, 0))`,
              backgroundPosition: 'bottom right',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            Graph here
          </Box>
        </Grid> */}
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: '10px',
            }}
          >
            <Typography
              sx={{
                color: analytics.isGreen ? '#029161' : '#EB5757',
                fontSize: '12px',
                paddingBottom: '15px',
              }}
            >
              {analytics.analysis}
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.grey
                    : ThemePalette.typography.black,
                }}
              >
                {' '}
                {analytics.message}
              </span>
            </Typography>
          </Grid>
          {analytics.showProgressBar ? (
            <ProgressBarWithArrow
              value={analytics.progressBarValue}
              enableDarkTheme={enableDarkTheme}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  );
};

export default CardAnalytics;
