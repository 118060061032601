import { Grid } from '@mui/material';
import CardAnalytics from 'views/Production/components/CardAnalytics/CardAnalytics';
import type { fleetDataSummary } from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { convertSeconds } from '../utils';
import { convertMetricTonsToImperialTons } from 'utils/helpers/unitSystem';
import {
  evaluateNumber,
  numberEvaluationKeyMappings,
  getFleetKpiThreshold,
  getGridDataPoints,
} from 'views/FleetOverview/components/AssetCardsV2/utils';

interface Props {
  enableDarkTheme: boolean;
  data: fleetDataSummary;
  isLoading: boolean;
  unitSystem: string;
  assetOverview: any;
}

const isNegative = (value: number) => value < 0;

const returnAnalysis = (value: number | string) => {
  return `${value}% ${isNegative(Number(value)) ? 'less' : 'more'}`;
};

const AnalyticsWrapper = ({
  enableDarkTheme,
  data,
  isLoading,
  unitSystem,
  assetOverview,
}: Props) => {
  const productionData = getGridDataPoints(assetOverview);
  const kpiThresholds = getFleetKpiThreshold(assetOverview.kpiThresholds);
  const thresholdMappings = numberEvaluationKeyMappings('total');

  const totalLoad =
    unitSystem === 'imperial'
      ? convertMetricTonsToImperialTons(data.total_load)
      : data.total_load;

  const avgTonDay =
    unitSystem === 'imperial'
      ? convertMetricTonsToImperialTons(data.avgTonDay)
      : data.avgTonDay;

  const avgLoad =
    unitSystem === 'imperial'
      ? convertMetricTonsToImperialTons(data.avgTon)
      : data.avgTon;

  const productionValue = evaluateNumber(
    productionData.fleet.production,
    thresholdMappings.productivity,
    kpiThresholds
  );

  const tr = [
    {
      title: 'Production',
      pointingUp: true,
      value: convertToTwoDecimalPlaces(totalLoad.toString()),
      unit: null,
      isGreen: !isNegative(data.total_load_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(data.total_load_percentage_diff.toString())
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: true,
      progressBarValue: productionValue,
    },
    {
      title: 'Avg Loads / Day',
      pointingUp: false,
      value: convertToTwoDecimalPlaces((avgTonDay ?? 0).toString()),
      unit: null,
      isGreen: !isNegative(data.avgTonDay_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(data.avgTonDay_percentage_diff.toString())
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 1,
      showProgressBar: false,
    },
    {
      title: 'Cycle Time',
      pointingUp: false,
      value: convertToTwoDecimalPlaces(
        convertSeconds(data.cycle_time ?? 0).value.toString()
      ),
      unit: convertSeconds(data.cycle_time ?? 0).unit ?? 'Minutes',
      isGreen: !isNegative(data.cycle_time_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(data.cycle_time_percentage_diff.toString())
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
    },
    {
      title: 'Avg Load',
      pointingUp: false,
      value: convertToTwoDecimalPlaces((avgLoad ?? 0).toString()),
      unit: unitSystem === 'imperial' ? 'lbs' : 'Tons',
      isGreen: !isNegative(data.avgTon_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(data.avgTon_percentage_diff.toString())
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 1,
      showProgressBar: false,
    },
    {
      title: 'Pass Count / Cycle',
      pointingUp: true,
      value: convertToTwoDecimalPlaces(data.cycle_count.toString()),
      unit: null,
      isGreen: !isNegative(data.cycle_count_percentage_diff),
      analysis: returnAnalysis(
        convertToTwoDecimalPlaces(data.cycle_count_percentage_diff.toString())
      ),
      message: 'from last period',
      hasWarning: false,
      warningCount: 0,
      showProgressBar: false,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        paddingTop: '15px',
      }}
    >
      {tr.map((item, index) => {
        return (
          <Grid item key={index} lg={2.4} md={6} sm={12} xs={12}>
            <CardAnalytics
              enableDarkTheme={enableDarkTheme}
              analytics={item}
              isLoading={isLoading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AnalyticsWrapper;
