// src/components/HealthScore.tsx
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, Sector } from 'recharts';
import { Box } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ThemePalette } from 'mui.theme';

const RADIAN = Math.PI / 180;

interface HealthScoreProps {
  data: Array<{ name: string; value: number; color: string }>;
  value: number; // The value the needle points to
  width?: number;
  height?: number;
  innerRadius?: number;
  outerRadius?: number;
  needleColor?: string;
  label?: string; // Optional label text
  arrowDirection?: boolean; // Prop to determine arrow direction
  enableDarkTheme?: boolean; // Prop to determine arrow direction
}

const needle = (
  value: number,
  data: Array<{ name: string; value: number }>,
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string
) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle
      key="needle-base"
      cx={x0}
      cy={y0}
      r={r}
      fill={color}
      stroke="none"
    />,
    <path
      key="needle-path"
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      fill={color}
    />,
  ];
};

const renderActiveShape = (
  props: any,
  label?: string,
  arrowDirection?: boolean,
  color?: string,
  enableDarkTheme?: boolean
) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={color ?? '#8884d8'} // Use the color from props or a default
      />
      {/* Label and arrow beside it */}
      {label && (
        <g>
          <text
            x={Number(cx) + 5} // Adjust x position for arrow
            y={Number(cy) + Number(outerRadius) - 15} // Position below the pie chart
            textAnchor="end" // Align text to the end for proper positioning
            fontSize={16}
            fontWeight="bold"
            fill={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
            style={{ cursor: 'pointer' }} // Add cursor pointer
          >
            {label}
          </text>
          {/* Using foreignObject to insert the arrow icon */}
          <foreignObject
            x={Number(cx) + 5} // Position next to the label
            y={Number(cy) + Number(outerRadius) - 35} // Align vertically with the label
            width={20}
            height={20}
            style={{ cursor: 'pointer' }} // Add cursor pointer
          >
            {arrowDirection ? (
              <ArrowDropUp
                style={{
                  fontSize: '30px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              />
            ) : (
              <ArrowDropDown
                style={{
                  fontSize: '30px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              />
            )}
          </foreignObject>
        </g>
      )}
    </g>
  );
};

class HealthScorePie extends PureComponent<HealthScoreProps> {
  static defaultProps = {
    width: 400,
    height: 500,
    innerRadius: 50,
    outerRadius: 100,
    needleColor: '#d0d000',
    label: '', // Default label is empty
  };

  handleFocus = (e: any) => {
    e.target.style.outline = 'none';
  };

  render() {
    const {
      data,
      value,
      width = 400, // Default value
      height = 500, // Default value
      innerRadius = 50, // Default value
      outerRadius = 100, // Default value
      needleColor = '#d0d000', // Default value
      label, // Optional label text
      arrowDirection = false, // Arrow direction state passed from parent
      enableDarkTheme,
    } = this.props;

    const cx = width / 2;
    const cy = height / 2;

    // Determine the active segment's color
    const activeSegment = data[0]; // Assuming the active segment is the first one
    const activeColor = activeSegment?.color;

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        onFocus={this.handleFocus}
      >
        <PieChart width={width} height={height}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx - 3}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            stroke="none"
            activeIndex={0} // Set the first segment as active
            activeShape={(props: any) =>
              renderActiveShape(
                props,
                label,
                arrowDirection,
                activeColor,
                enableDarkTheme
              )
            } // Use custom active shape with label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, innerRadius, outerRadius, needleColor)}
        </PieChart>
      </Box>
    );
  }
}

export default HealthScorePie;
