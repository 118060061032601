import { TableCell, TableRow, Typography, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

interface Props {
  enableDarkTheme: boolean;
  item: any;
}

const TableItem = ({ enableDarkTheme, item }: Props) => {
  return (
    <>
      <TableRow
        sx={{
          border: 'none',
        }}
      >
        <TableCell
          sx={{
            border: 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: item.color,
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
            <Typography
              color="#d1d5db"
              sx={{
                fontSize: '11px',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          align="right"
          sx={{
            border: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              color="#d1d5db"
              sx={{
                marginRight: '4px',
                fontSize: '11px',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              {item.time}
            </Typography>
            {item.direction === 'up' ? (
              <NorthEastIcon sx={{ color: '#EB5757', fontSize: 13 }} />
            ) : (
              <SouthEastIcon sx={{ color: '#029161', fontSize: 13 }} />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableItem;
