import { Alert, Box, Collapse, IconButton } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import React, { useState, useEffect, useRef } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

// Define the type for the data items
interface DataItem {
  month: number;
  cumulative_assets: number;
  asset_change?: number;
}

export const SurvivalAnalysisProgressDotLine = ({
  value,
  enableDarkTheme,
}: any) => {
  // Initialize state with an empty array of type DataItem
  const [chartData, setChartData] = useState<DataItem[]>([]);
  const [maxAssetChange, setMaxAssetChange] = useState(1); // Initialize with a minimum value of 1

  useEffect(() => {
    // Check if value is valid and process the data
    if (value && Array.isArray(value) && value.length > 0) {
      // Ensure the first item is defined and has a valid cumulative_assets value
      let previousValue = value[0]?.cumulative_assets ?? 1;

      const processedData = value.map((item: DataItem, index: number) => {
        if (!item || item.cumulative_assets === undefined) {
          item = { ...item, cumulative_assets: 1 };
        }

        // Set asset change to 0 if cumulative_assets has not changed; otherwise, calculate the change
        const assetChange =
          item.cumulative_assets !== previousValue
            ? item.cumulative_assets - previousValue
            : 0;

        previousValue = item.cumulative_assets;

        return {
          ...item,
          asset_change: assetChange,
        };
      });

      setChartData(processedData);

      // Calculate the maximum asset change value for setting Y-axis ticks
      const maxChange = Math.max(
        ...processedData.map((d) => d.asset_change || 1)
      );
      setMaxAssetChange(maxChange);
    }
  }, [value]); // Runs every time the value prop changes
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={containerRef}
      sx={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <ResponsiveContainer width="100%" height="75%">
        <AreaChart
          data={chartData}
          margin={{
            top: 20,
            bottom: 40, // Increased bottom margin for X-axis label
            left: -30,
            right: 2,
          }}
        >
          <defs>
            <linearGradient id="colorAssetChange" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            stroke={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
            dataKey="month"
            label={{
              value: 'Month',
              position: 'bottom',
              offset: -5, // Adjusted offset for the label position
              fill: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: 12,
            }}
          />
          <YAxis
            stroke={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
            ticks={Array.from({ length: maxAssetChange + 1 }, (_, i) => i)}
            domain={[0, maxAssetChange]}
            allowDecimals={false}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="asset_change"
            stroke={
              enableDarkTheme
                ? ThemePalette.typography.cyan
                : ThemePalette.typography.cornflowerBlue
            }
            fillOpacity={1}
            fill="url(#colorAssetChange)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
