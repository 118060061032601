export const tags = [
  {
    id: 'j1708ABSControlStatus',
    label: 'ABSControlStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939AcceleratorPedal_2_LowIdleSwitch',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939AcceleratorPedalPosition_1',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine_1_accelerator_pedal_position_1',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939AcceleratorPedalPosition_2',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708PercentAccelerator',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708PercentAcceleratorPosition',
    label: 'Accelerator Pedal Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939ActualEnginePercentTorque',
    label: 'ActualEnginePercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939ActualEnginePercentTorqueHighResolution',
    label: 'ActualEnginePercentTorqueHighResolution',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlActualEngineTiming',
    label: 'ActualEngineTiming',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939ActualMaximumAvailableEnginePercentTorque',
    label: 'ActualMaximumAvailableEnginePercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939ActualMaximumAvailableRetarderPercentTorque',
    label: 'ActualMaximumAvailableRetarderPercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939ActualRetarderPercentTorque',
    label: 'ActualRetarderPercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineAftercoolerCoolantLevel',
    label: 'AftercoolerCoolantLevel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939Aftertreatment_1_FuelPressure_1',
    label: 'aftertreatment1fuelpressure_1',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939Aftertreatment_1_FuelPressure_2',
    label: 'aftertreatment1fuelpressure_2',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939Aftertreatment_1_FuelRate',
    label: 'aftertreatment1fuelrate',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939Aftertreatment_1_HydrocarbonDoserIntakeFuelTemperature',
    label: 'Aftertreatment1HydrocarbonDoserIntakeFuelTemperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineAirFilter_1_DifferentialPressure',
    label: 'Air Filter 1 Differential Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineAirFilter_2_DifferentialPressure',
    label: 'Air Filter 2 Differential Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineAirFilter_3_DifferentialPressure',
    label: 'Air Filter 3 Differential Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineAirFilter_4_DifferentialPressure',
    label: 'Air Filter 4 Differential Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AirFilterDifferentialPressure',
    label: 'Air Filter Differential Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AirInletTemp',
    label: 'Air Inlet Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AirInletPressure',
    label: 'Air Intake Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineAirIntakePressure',
    label: 'Air Intake Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AirConditionerSystemStatus',
    label: 'AirConditionerSystemStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AirConditionerSystemStatus_2',
    label: 'AirConditionerSystemStatus_2',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AirConditionerSystemStatusCommand_1',
    label: 'AirConditionerSystemStatusCommand_1',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlAirFilterRestriction',
    label: 'AirFilterRestriction',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AirStartPressure',
    label: 'AirStartPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939AlternatorCurrent',
    label: 'Alternator Current',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AlternatorCurrent',
    label: 'Alternator Current',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AmbientAirTemp',
    label: 'Ambient Air Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlAmbientAirTemperature',
    label: 'Ambient Air Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939AmbientAirTemperature',
    label: 'Ambient Air Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_ambientairtemperature',
    label: 'Ambient Air Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708ATCControlStatus',
    label: 'ATCControlStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AttentionWarningIndicatorLampsStatus',
    label: 'AttentionWarningIndicatorLampsStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxiliaryAbsoluteReadingPressure',
    label: 'AuxiliaryAbsoluteReadingPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxiliaryGagePressureReading_1',
    label: 'AuxiliaryGagePressureReading_1',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxiliaryInputAndOutputStatus_1',
    label: 'AuxiliaryInputAndOutputStatus_1',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxiliaryInputAndOutputStatus_2',
    label: 'AuxiliaryInputAndOutputStatus_2',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxiliaryVacuumPressureReading',
    label: 'AuxiliaryVacuumPressureReading',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AuxilliaryWaterPumpPressure',
    label: 'AuxilliaryWaterPumpPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AverageFuelEconomy',
    label: 'Average Fuel Rate',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AverageFuelRate',
    label: 'Average Fuel Rate',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AverageFuelEconomyNaturalGas',
    label: 'Average Fuel Rate (Natural Gas)',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708Axle_1_LiftAirPressure',
    label: 'Axle 1 Lift Air Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708Axle_2_LiftAirPressure',
    label: 'Axle 2 Lift Air Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AxleLiftStatusAxleLiftPosition',
    label: 'AxleLiftStatusAxleLiftPosition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AxleLiftStatusAxleLiftSwitch',
    label: 'AxleLiftStatusAxleLiftSwitch',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AxleSliderStatusAxleSliderLockStatus',
    label: 'AxleSliderStatusAxleSliderLockStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708AxleSliderStatusAxleSliderLockSwitchStatus',
    label: 'AxleSliderStatusAxleSliderLockSwitchStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlAtmosphericPressure',
    label: 'Barometric Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939BarometricPressure',
    label: 'Barometric Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708BarometricPressure',
    label: 'Barometric Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939NetBatteryCurrent',
    label: 'Battery Current',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708BatteryPotentialVoltage',
    label: 'Battery Voltage',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939KeyswitchBatteryPotential',
    label: 'Battery Voltage - Keyswitch',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708AlternatorPotentialVoltage',
    label: 'Battery Voltage Input',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939BatteryPotentialPowerInput',
    label: 'Battery Voltage Input',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939ChargingSystemPotentialVoltage',
    label: 'Battery Voltage Input',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708BatteryPotentialVoltageSwitched',
    label: 'Battery Voltage Switched',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708BlowerBypassValvePosition',
    label: 'BlowerBypassValvePosition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlBodyPositionAngle',
    label: 'BodyPositionAngle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlBodyUpChassis',
    label: 'BodyUpChassis',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlBoostPressureGauge',
    label: 'BoostPressureGauge',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeApplicationPressure',
    label: 'BrakeApplicationPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlBrakeFilterBypassStatus',
    label: 'BrakeFilterBypassStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_brakeoilpressureleftfront',
    label: 'brakeoilpressureleftfront',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_brakeoilpressurerightrear',
    label: 'brakeoilpressurerightrear',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakePrimaryPressure',
    label: 'BrakePrimaryPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSecondaryPressure',
    label: 'BrakeSecondaryPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeStrokeStatus',
    label: 'BrakeStrokeStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSwitchStatusService',
    label: 'BrakeSwitchStatusService',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSwitchStatusSystem',
    label: 'BrakeSwitchStatusSystem',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSystemAirPressureLowWarningSwitchStatusEmergencyReservoirTrailer',
    label:
      'BrakeSystemAirPressureLowWarningSwitchStatusEmergencyReservoirTrailer',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSystemAirPressureLowWarningSwitchStatusPrimaryReservoirPoweredVehicle',
    label:
      'BrakeSystemAirPressureLowWarningSwitchStatusPrimaryReservoirPoweredVehicle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSystemAirPressureLowWarningSwitchStatusSecondaryReservoirPoweredVehicle',
    label:
      'BrakeSystemAirPressureLowWarningSwitchStatusSecondaryReservoirPoweredVehicle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708BrakeSystemAirPressureLowWarningSwitchStatusServiceReservoirTrailer',
    label:
      'BrakeSystemAirPressureLowWarningSwitchStatusServiceReservoirTrailer',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'CarbonMonoxidePPM',
    label: 'CarbonMonoxidePPM',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineChargeAirCoolerOutletPressure',
    label: 'ChargeAirCoolerOutletPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ClutchPressure',
    label: 'Clutch Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939PercentClutchSlip',
    label: 'Clutch Slip',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ClutchPlatesWearCondition',
    label: 'ClutchPlatesWearCondition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'connection_updated',
    label: 'connection updated',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantFilterDifferentialPressure',
    label: 'Coolant Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708CoolantFilterDifferentialPressure',
    label: 'Coolant Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708CoolantLevel',
    label: 'Coolant Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantLevel',
    label: 'Coolant Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantPressure',
    label: 'Coolant Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708CoolantPressure',
    label: 'Coolant Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantPumpDifferentialPressure',
    label: 'Coolant Pump Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantPumpOutletTemperature',
    label: 'Coolant Pump Outlet Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlEngineCoolantTemp',
    label: 'Coolant Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708EngineCoolantTemp',
    label: 'Coolant Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineCoolantTemperature',
    label: 'Coolant Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine1coolanttemperature',
    label: 'Coolant Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineCoolantThermostatMode',
    label: 'CoolantThermostatMode',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCoolantThermostatOpening',
    label: 'CoolantThermostatOpening',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708CrankcaseBlowByPressure',
    label: 'Crankcase Blow-By Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineCrankcasePressure',
    label: 'Crankcase Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708CrankcasePressure',
    label: 'Crankcase Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'creation_date',
    label: 'creation date',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlCurrentGear',
    label: 'Current Gear',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlSelectedGear_2',
    label: 'Current Gear',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939TransmissionCurrentGear',
    label: 'Current Gear',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineDemandPercentTorque',
    label: 'DemandPercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlDesiredEngineSpeed',
    label: 'DesiredEngineSpeed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineDPFIntakePressure',
    label: 'DPFIntakePressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DriverDemandEnginePercentTorque',
    label: 'DriverDemandEnginePercentTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCAmberWarningLamp',
    label: 'DTCAmberWarningLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCFlashAmberWarningLamp',
    label: 'DTCFlashAmberWarningLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCFlashMalfunctionIndicatorLamp',
    label: 'DTCFlashMalfunctionIndicatorLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCFlashProtectLamp',
    label: 'DTCFlashProtectLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCFlashRedStopLamp',
    label: 'DTCFlashRedStopLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCMalfunctionIndicatorLamp',
    label: 'DTCMalfunctionIndicatorLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCProtectLamp',
    label: 'DTCProtectLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939DTCRedStopLamp',
    label: 'DTCRedStopLamp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'EdgeOdometer',
    label: 'EdgeOdometer',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlPercentEngineLoad',
    label: 'Engine Load',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708PercentEngineLoad',
    label: 'Engine Load',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlEnginerpm',
    label: 'Engine rpm',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939Enginerpm',
    label: 'Engine rpm',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine1speed',
    label: 'Engine rpm',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708EngineSpeed',
    label: 'Engine rpm',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_total_vehicle_hours',
    label: 'Engine Run Time',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'obd_2_EngineRunTime',
    label: 'Engine Run Time',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708EngineOilLevelRemoteReservoir',
    label: 'EngineOilLevelRemoteReservoir',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708EngineRetarderPercent',
    label: 'EngineRetarderPercent',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708EngineRetarderStatus',
    label: 'EngineRetarderStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ExhaustBackPressure',
    label: 'Exhaust Back Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708ExhaustGasTemp',
    label: 'Exhaust Gas Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineExhaustGasTemperature',
    label: 'Exhaust Gas Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708ExtendedCrankcaseBlowByPressure',
    label: 'ExtendedCrankcaseBlowByPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineExtendedCrankcaseBlowbyPressure',
    label: 'ExtendedCrankcaseBlowbyPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ExtendedRangeFuelDeliveryPressureAbs',
    label: 'ExtendedRangeFuelDeliveryPressureAbs',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ExtRangeEngineCoolantPressure',
    label: 'ExtRangeEngineCoolantPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'first_event',
    label: 'first event',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_footbrakeposition',
    label: 'foot brake position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_footbrakeposition_2',
    label: 'foot brake position _2',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708ForwardRearDriveAxleTemperature',
    label: 'Forward-Rear Drive Axle Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708FrontAxleWeight',
    label: 'Front Axle Weight',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlFilteredEngineFuelPressureAbs',
    label: 'Fuel Delivery Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708FuelDeliveryPressure',
    label: 'Fuel Delivery Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineFuelDeliveryPressure',
    label: 'Fuel Delivery Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineFuelFilterDifferentialPressure',
    label: 'Fuel Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708FuelFilterDifferentialPressure',
    label: 'Fuel Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708FuelLevel',
    label: 'Fuel Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939FuelLevel_1',
    label: 'Fuel Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939FuelLevel_2',
    label: 'Fuel Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlEngineFuelLevelPercent',
    label: 'Fuel Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlFuelConsumptionRateLPH',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineFuelRate',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine1fuelrate',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708FuelRateInstantaneous',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708InstantaneousFuelEconomy',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineInstantaneousFuelEconomy',
    label: 'Fuel Rate',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708FuelTemp',
    label: 'Fuel Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlFuelTemperature',
    label: 'Fuel Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineFuelTemperature_1',
    label: 'Fuel Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineFuelTemperature_2',
    label: 'Fuel Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlFuelTransferPumpIntakePressure',
    label: 'Fuel Transfer Pump Intake Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineFuelActuator1ControlCommand',
    label: 'FuelActuator1ControlCommand',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineFuelFilterDegradation',
    label: 'FuelFilterDegradation',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlFuelFilterStatus',
    label: 'FuelFilterStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_engine1fuelinjection',
    label: 'fuelinjection',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_fuellevelhighresolution',
    label: 'fuellevelhighresolution',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlfuelrailpressuregauge',
    label: 'fuelrailpressuregauge',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708FuelSupplyPumpInletPressure',
    label: 'FuelSupplyPumpInletPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708GeneratorOilPressure',
    label: 'GeneratorOilPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlGeneratorSetHourMeter',
    label: 'GeneratorSetHourMeter',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'gps fix quality',
    label: 'gps fix quality',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSAltitude',
    label: 'GPSAltitude',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSAngle',
    label: 'GPSAngle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSFixQuality',
    label: 'GPSFixQuality',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSLatitude',
    label: 'GPSLatitude',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSLongitude',
    label: 'GPSLongitude',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'GPSVelocity',
    label: 'GPSVelocity',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939FrontAxleLeftWheelSpeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939Frontaxlerightwheelspeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlGroundSpeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939NavigationBasedVehicleSpeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939Rearaxleleftwheelspeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939Rearaxlerightwheelspeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939WheelBasedVehicleSpeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_wheelbasedvehiclespeed',
    label: 'Ground Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_highresolutiontotalvehicledistance',
    label: 'highresolutiontotalvehicledistance',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlHydraulicOilTemp',
    label: 'Hydraulic Oil Temp',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939HydraulicPressure',
    label: 'Hydraulic Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939HydraulicOilLevel',
    label: 'HydraulicOilLevel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708IdleEngineSpeed',
    label: 'IdleEngineSpeed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineInjectorMeteringRail1Pressure',
    label: 'Injector Metering Rail Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineIntakeManifold1AbsolutePressure',
    label: 'Intake Manifold Absolute Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlIntakeManifold1AirPressure',
    label: 'Intake Manifold Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineIntakeManifold1Pressure',
    label: 'Intake Manifold Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlIntakeManifold_2_AirPressure',
    label: 'Intake Manifold Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineIntakeManifold1Temperature',
    label: 'Intake Manifold Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlIntakeManifoldAirTemperature',
    label: 'Intake Manifold Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708IntakeManifoldTemp',
    label: 'Intake Manifold Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708EngineIntercoolerTemperature',
    label: 'Intercooler Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineIntercoolerTemperature',
    label: 'Intercooler Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineIntercoolerCoolantLevel',
    label: 'IntercoolerCoolantLevel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineIntercoolerThermostatOpening',
    label: 'IntercoolerThermostatOpening',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlLeftBankTurbineInletTemperature',
    label: 'Left Bank Turbine Inlet Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlLeftBankTurbineOutletTemperature',
    label: 'Left Bank Turbine Outlet Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlLeftFinalDriveOilTemperature',
    label: 'Left Final Drive Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlLeftFrontBrakeOilTemperature',
    label: 'Left Front Brake Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlLeftFrontStrutPressure',
    label: 'Left Front Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_leftfrontsuspensionpressure',
    label: 'Left Front Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlLeftRearBrakeOilTemperature',
    label: 'Left Rear Brake Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlLeftRearStrutPressure',
    label: 'Left Rear Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_leftrearsuspensionpressure',
    label: 'Left Rear Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlLeftTurboInletPressureAbs',
    label: 'Left Turbo Inlet Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'obd2FuelTankLevelInput',
    label: 'obd2FuelTankLevelInput',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2NetworkDTC',
    label: 'obd2NetworkDTC',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2PowertrainDTC',
    label: 'obd2PowertrainDTC',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2SupportedPIDs_01_To_20',
    label: 'obd2SupportedPIDs01To20',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2SupportedPIDs_21_To_40',
    label: 'obd2SupportedPIDs21To40',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2SupportedPIDs41To60',
    label: 'obd2SupportedPIDs41To60',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2SupportedPIDs61To80',
    label: 'obd2SupportedPIDs61To80',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2VIN',
    label: 'obd2VIN',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708EngineOilFilterDifferentialPressure',
    label: 'Oil Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineOilFilterDifferentialPressure',
    label: 'Oil Filter Differential Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708EngineOilLevel',
    label: 'Oil Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineOilLevel',
    label: 'Oil Level',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708EngineOilPressure',
    label: 'Oil Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine1oilpressure',
    label: 'Oil Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineOilPressure',
    label: 'Oil Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlEngineOilPressureAbs',
    label: 'Oil Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708EngineOilTemp',
    label: 'Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlEngineOilTemperature',
    label: 'Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine1oiltemperature_1',
    label: 'Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlEngineOilTemperature_1',
    label: 'Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineOilTemperature_1',
    label: 'Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineOilLevelRemoteReservoir',
    label: 'OilLevelRemoteReservoir',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708OutputTorque',
    label: 'OutputTorque',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlParkingBrake',
    label: 'ParkingBrake',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939ParkingBrakeSwitch',
    label: 'ParkingBrakeSwitch',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ParkingBrakeSwitchStatus',
    label: 'ParkingBrakeSwitchStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708ParticulateTrapInletPressure',
    label: 'ParticulateTrapInletPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlPayloadStatus',
    label: 'PayloadStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708PIDOverRangeWarningCondition',
    label: 'PIDOverRangeWarningCondition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939PitchAngle',
    label: 'PitchAngle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_pitchinclinometer',
    label: 'pitchinclinometer',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlEnginePowerDerate',
    label: 'PowerDerate',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708PowerSpecificFuelEconomy',
    label: 'PowerSpecificFuelEconomy',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708PowerTakeoffOilTemperature',
    label: 'PowerTakeoffOilTemperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708PowerTakeoffSetSpeed',
    label: 'PowerTakeoffSetSpeed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708PowerTakeoffStatus',
    label: 'PowerTakeoffStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineProtectionSystemApproachingShutdown',
    label: 'ProtectionSystemApproachingShutdown',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708RearRearDriveAxleTemperature',
    label: 'Rear-Rear Drive Axle Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_retarderposition',
    label: 'retarderposition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlRightBankTurbineInletTemperature',
    label: 'Right Bank Turbine Inlet Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlRightBankTurbineOutletTemperature',
    label: 'Right Bank Turbine Outlet Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlRightFinalDriveOilTemperature',
    label: 'Right Final Drive Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlRightFrontBrakeOilTemperature',
    label: 'Right Front Brake Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlRightFrontStrutPressure',
    label: 'Right Front Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_rightfrontsuspensionpressure',
    label: 'Right Front Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlRightRearBrakeOilTemperature',
    label: 'Right Rear Brake Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlRightRearStrutPressure',
    label: 'Right Rear Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_rightrearsuspensionpressure',
    label: 'Right Rear Suspension Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlRightTurboInletPressureAbs',
    label: 'Right Turbo Inlet Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939RoadSurfaceTemperature',
    label: 'RoadSurfaceTemperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939RollAngle',
    label: 'RollAngle',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SB%MemoryUsed',
    label: 'SB%MemoryUsed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBAccelerationX',
    label: 'SBAccelerationX',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBAccelerationY',
    label: 'SBAccelerationY',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBAccelerationZ',
    label: 'SBAccelerationZ',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBAvailMemory',
    label: 'SBAvailMemory',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBBatteryVoltage',
    label: 'SBBatteryVoltage',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBIgnitionStatus',
    label: 'SBIgnitionStatus',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBLteSignalStrength',
    label: 'SBLteSignalStrength',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBMemoryUsed',
    label: 'SBMemoryUsed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBNumberOfPointsTransmitted',
    label: 'SBNumberOfPointsTransmitted',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBTemperature',
    label: 'SBTemperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBTransmissionConnect',
    label: 'SBTransmissionConnect',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SBTransmissionDisconnect',
    label: 'SBTransmissionDisconnect',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708SecondFuelLevel',
    label: 'SecondFuelLevel|7|3|8.5|26.987',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlServiceBrakePosition',
    label: 'ServiceBrakePosition',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708SteerChannelTirePressure',
    label: 'Steer Channel Tire Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708SteeringAxleTemperature',
    label: 'Steering Axle Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlSteeringFluidTemperature',
    label: 'Steering Fluid Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlSteeringPumpOilPressure',
    label: 'Steering Pump Oil Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708SuctionSideFuelFilterDifferentialPressure',
    label: 'SuctionSideFuelFilterDifferentialPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SymRunTime',
    label: 'SymRunTime',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SymStart',
    label: 'SymStart',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'SymStop',
    label: 'SymStop',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlSystemBatteryVoltageAll',
    label: 'SystemBatteryVoltageAll',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlSystemVoltage',
    label: 'SystemVoltage',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlThrottlePosition',
    label: 'Throttle Position',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_torqueconverterlockupengaged',
    label: 'torqueconverterlockupengaged',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708TorqueLimitingFactor',
    label: 'TorqueLimitingFactor',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalEngineIdleTime',
    label: 'TotalEngineIdleTime',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalFuelConsumed',
    label: 'TotalFuelConsumed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineTotalFuelUsed',
    label: 'TotalFuelUsed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineTotalHoursOfOperation',
    label: 'TotalHoursOfOperation',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalLoadCycles',
    label: 'TotalLoadCycles',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalLoadedDistance',
    label: 'TotalLoadedDistance',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalOperatingHoursAll',
    label: 'TotalOperatingHoursAll',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTotalOperatingHoursEngine',
    label: 'TotalOperatingHoursEngine',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708TotalPTOHours',
    label: 'TotalPTOHours',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTransmissionOilPressureAbs',
    label: 'Transmission Oil Pressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTransmissionOilTemperature',
    label: 'Transmission Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_transmissionoiltemperature_1',
    label: 'Transmission Oil Temperature',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'kj1939_transmissioncurrentrange',
    label: 'transmissioncurrentrange',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTransmissionOutputSpeed',
    label: 'TransmissionOutputSpeed',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708TransmissionTankAirPressure',
    label: 'TransmissionTankAirPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708TransmitterSystemDiagnostic',
    label: 'TransmitterSystemDiagnostic',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708TripFuel',
    label: 'Trip Fuel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939EngineTripFuel',
    label: 'Trip Fuel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708CargoWeight',
    label: 'Truck Payload',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_liveweight',
    label: 'Truck Payload',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlTruckPayload',
    label: 'Truck Payload',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708BoostPressure',
    label: 'Turbo Boost Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'kj1939_engine_1_turbochargerboostpressure',
    label: 'Turbo Boost Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708TurboOilPressure',
    label: 'Turbo Oil Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708TurboOilTemp',
    label: 'Turbo Oil Temp',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlTurboOutletPressureAbs',
    label: 'Turbo Outlet Pressure Abs',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708TurboSpeed',
    label: 'Turbo Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlTurbocharger_1_CompressorInletPressure',
    label: 'Turbocharger 1 Compressor Inlet Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineTurbocharger_1_TurbineIntakeTemperature',
    label: 'Turbocharger 1 Turbine Intake Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlTurbocharger_2_CompressorInletPressure',
    label: 'Turbocharger 2 Compressor Inlet Pressure',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineTurbocharger_2_TurbineIntakeTemperature',
    label: 'Turbocharger 2 Turbine Intake Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1939EngineTurbochargerOilTemperature',
    label: 'Turbocharger Oil Temperature',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'cdlTurbocharger_3_CompressorInletPressure',
    label: 'Turbocharger3CompressorInletPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'cdlTurbocharger_4_CompressorInletPressure',
    label: 'Turbocharger4CompressorInletPressure',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708UnderRangeWarningCondition',
    label: 'UnderRangeWarningCondition|1|31|31|0.0',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708UnknownMID=00 00 00 80,PID=00 00 00 C_2',
    label: 'UnknownMID=00 00 00 80,PID=00 00 00 C_2',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939WasherFluidLevel',
    label: 'WasherFluidLevel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708WasherFluidLevel',
    label: 'WasherFluidLevel',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1708WaterInFuelIndicator',
    label: 'WaterInFuelIndicator',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'j1939WaterInFuelIndicator',
    label: 'waterinfuelindicator',
    includeInBenchmarkScreen: 'N',
  },
  {
    id: 'obd2VehicleSpeed',
    label: 'Wheel Speed',
    includeInBenchmarkScreen: 'Y',
  },
  {
    id: 'j1708WheelSpeedSensorStatus',
    label: 'WheelSpeedSensorStatus',
    includeInBenchmarkScreen: 'N',
  },
];
