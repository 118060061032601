import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';

import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import SuggestionItems from './SuggestionItems';

interface Props {
  enableDarkTheme: boolean;
  minHeight: string;
}

const alerts = [
  {
    id: 1,
    code: 'HT - 242',
    message: 'is overloaded',
    description:
      'Overloading the truck causes increase in fuel consumption, reduces fire risks.',
    time: '12h ago',
    icon: <ErrorIcon style={{ color: '#F44336' }} />,
  },
  {
    id: 2,
    code: 'HT - 7762',
    message: 'is Idle for 3 hours',
    description: 'Turn off the engine to save fuel',
    time: '3h ago',
    icon: <WarningIcon style={{ color: '#FFB402' }} />,
    action: 'Ping operator',
  },
  {
    id: 3,
    code: 'HT - 89',
    message: 'is Idle for 1 hour',
    description: 'Turn off the engine to save fuel',
    time: '3h ago',
    icon: <WarningIcon style={{ color: '#FFB402' }} />,
    action: 'Ping operator',
  },
];

const Suggestions = ({ enableDarkTheme, minHeight }: Props) => {
  return (
    <Grid item lg={3} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight,
          maxHeight: '300px', // Fixed height for the list
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          Suggestions
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          {alerts.map((item, index) => {
            return (
              <SuggestionItems
                enableDarkTheme={enableDarkTheme}
                alert={item}
                key={index}
                isLastItem={index === alerts.length - 1}
              />
            );
          })}
        </Box>
      </Box>
    </Grid>
  );
};

export default Suggestions;
