import { Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { convertMetricTonsToImperialTons } from 'utils/helpers/unitSystem';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';

interface Props {
  enableDarkTheme: boolean;
  target: number;
  value: number;
  unitSystem: string;
}

const CircularProgressChart = ({
  enableDarkTheme,
  target,
  value,
  unitSystem,
}: Props) => {
  let percentageValue = (value / target) * 100;

  if (percentageValue > 100) {
    percentageValue = 100;
  }
  return (
    <Box
      position="relative"
      display="inline-flex"
      sx={{
        backgroundColor: '#1a2b4a',
        borderRadius: '50%',
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={percentageValue}
        size={150}
        thickness={3}
        sx={{
          color: '#4b76f3',
          scale: '-1 1',
          transform: 'rotate(-90deg)',
        }}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: '#9fb8db',
        }}
      >
        <Typography
          component="div"
          color="inherit"
          sx={{
            fontSize: '12px',
          }}
        >
          YTD Actual
        </Typography>
        <Typography
          component="div"
          color="#4b76f3"
          sx={{
            fontSize: 'data',
            fontWeight: '600',
          }}
        >
          {convertToTwoDecimalPlaces(
            (unitSystem === 'imperial'
              ? convertMetricTonsToImperialTons(value)
              : value
            ).toString()
          )}
        </Typography>
        <Typography
          component="div"
          color="inherit"
          sx={{
            fontSize: '12px',
          }}
        >
          {unitSystem === 'imperial' ? 'lbs' : 'Tons'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressChart;
