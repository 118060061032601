import WeatherAPI from 'api/weather.api';
import { getAll, setIsLoading } from 'store/weather.slice';

import { BaseHandler } from './base.handler';

export default class WeatherHandler extends BaseHandler {
  private readonly api: WeatherAPI;

  constructor() {
    super();

    this.api = new WeatherAPI();
  }

  async get(): Promise<any> {
    this.dispatch(setIsLoading(true));

    try {
      const weather = await this.api.get();

      this.dispatch(setIsLoading(false));
      this.dispatch(getAll(weather));
      return weather;
    } catch (_) {
      this.handleError('An error occurred while fetching weather.');
      this.dispatch(setIsLoading(false));
    }

    return {};
  }
}
