import { useState } from 'react';

const useDateRange = (initialDate = null) => {
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const defaultDate = initialDate ?? yesterday;
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    yesterday,
  };
};

export default useDateRange;
