import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import TableItem from './TableItem';
import type { AveragrCycleTimeType } from 'types/production.types';

interface Props {
  enableDarkTheme: boolean;
  data: AveragrCycleTimeType[];
}

const TableList = ({ enableDarkTheme, data }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: '1px solid #2F445F' }}>
            <Typography
              color={
                enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black
              }
            >
              State
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: '1px solid #2F445F' }}>
            <Typography
              color={
                enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black
              }
            >
              Time
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((state, index) => (
          <TableItem
            enableDarkTheme={enableDarkTheme}
            item={state}
            key={index}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default TableList;
