/* eslint-disable @typescript-eslint/naming-convention */
import { type GeoTrip } from 'types/geo.types';
import { Paper, Typography } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { paperCardStyle, paperCardStyleDark } from './styles';
import { truncateTo2dp } from 'utils/helpers/general';
import { convertSeconds } from 'utils/helpers/dateTime';
import { useAppSelector } from 'store/hook';
import { getTripTime } from '../utils';

interface CardsProp {
  selectedTrip: GeoTrip;
  enableDarkTheme: boolean;
  ThemePalette: Record<string, any>;
}

const CardSection = ({
  selectedTrip,
  enableDarkTheme,
  ThemePalette,
}: CardsProp) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const {
    trip,
    unloaded_trip_start_ts,
    loaded_trip_start_ts,
    unloaded_trip_end_ts,
    loaded_trip_end_ts,
    loading_end_ts,
    loading_start_ts,
    unloading_end_ts,
    unloading_start_ts,
    max_load_ton,
    loaded_trip_duration_sec,
    unloaded_trip_duration_sec,
    loaded_trip_fuel_consumption_l,
    unloaded_trip_fuel_consumption_l,
    loading_fuel_consumption_l,
    unloading_fuel_consumption_l,
    loaded_trip_travel_distance_km,
    unloaded_trip_travel_distance_km,
  } = selectedTrip;
  const formatTripTime = (tripDurationSec: number): React.ReactNode => {
    const { hours, minutes, seconds } = convertSeconds(tripDurationSec);
    return (
      <>
        <span style={{ color: '#4A7BFA' }}>{hours}</span>
        <span
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Hrs,{' '}
        </span>
        <span style={{ color: '#4A7BFA' }}>{minutes}</span>
        <span
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Mins,{' '}
        </span>
        <span style={{ color: '#4A7BFA' }}>{seconds}</span>
        <span
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Sec
        </span>
      </>
    );
  };
  const { startTime, endTime } = getTripTime([
    unloaded_trip_start_ts,
    loaded_trip_start_ts,
    unloaded_trip_end_ts,
    loaded_trip_end_ts,
    loading_end_ts,
    loading_start_ts,
    unloading_end_ts,
    unloading_start_ts,
  ]);

  return (
    <Base
      sx={{
        width: '60%',
        height: '490px',
        marginLeft: '80px',
        backgroundColor: 'transparent',
        marginTop: '0px',
        color: enableDarkTheme ? 'grey' : ThemePalette.typography.black,
      }}
    >
      <Paper
        elevation={7}
        sx={enableDarkTheme ? paperCardStyleDark : paperCardStyle}
      >
        <div>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Trip Selected
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: '#4A7BFA',
            }}
          >
            {trip}
          </Typography>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {startTime} to {endTime}
          </Typography>
        </div>
      </Paper>
      <Paper
        elevation={7}
        sx={enableDarkTheme ? paperCardStyleDark : paperCardStyle}
      >
        <div>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Max Load Ton
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#4A7BFA',
            }}
          >
            {truncateTo2dp(max_load_ton)}
          </Typography>
        </div>
      </Paper>
      <Paper
        elevation={7}
        sx={enableDarkTheme ? paperCardStyleDark : paperCardStyle}
      >
        <div>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Cycle Time
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '18px', color: '#4A7BFA' }}
          >
            {formatTripTime(
              loaded_trip_duration_sec + unloaded_trip_duration_sec
            )}
          </Typography>
        </div>
      </Paper>
      <Paper
        elevation={7}
        sx={enableDarkTheme ? paperCardStyleDark : paperCardStyle}
      >
        <div>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Fuel Consumed in entire Trip
          </Typography>
          <Typography variant="h6">
            <span
              style={{
                color: '#4A7BFA',
              }}
            >
              {truncateTo2dp(
                (loaded_trip_fuel_consumption_l ?? 0) +
                  (unloaded_trip_fuel_consumption_l ?? 0) +
                  (loading_fuel_consumption_l ?? 0) +
                  (unloading_fuel_consumption_l ?? 0)
              )}{' '}
            </span>
            <span
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {unitSystem === 'metric' ? 'litres' : 'gals'}
            </span>
          </Typography>
        </div>
      </Paper>
      <Paper
        elevation={7}
        sx={enableDarkTheme ? paperCardStyleDark : paperCardStyle}
      >
        <div>
          <Typography
            variant="small"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Distance Travelled
          </Typography>
          <Typography variant="h6">
            <span
              style={{
                color: '#4A7BFA',
              }}
            >
              {truncateTo2dp(
                (loaded_trip_travel_distance_km ?? 0) +
                  (unloaded_trip_travel_distance_km ?? 0)
              )}{' '}
            </span>
            <span
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {unitSystem === 'metric' ? 'Kms' : 'Miles'}
            </span>
          </Typography>
          <Typography
            variant="small"
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Loaded Trip: {truncateTo2dp(loaded_trip_travel_distance_km ?? 0)}{' '}
            {unitSystem === 'metric' ? 'Kms' : 'Miles'} <br />
            Unloaded Trip:{' '}
            {truncateTo2dp(unloaded_trip_travel_distance_km ?? 0)}{' '}
            {unitSystem === 'metric' ? 'Kms' : 'Miles'}
          </Typography>
        </div>
      </Paper>
    </Base>
  );
};

export default CardSection;
