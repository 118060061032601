import { Box, TextField } from '@mui/material';
import { useState, type CSSProperties } from 'react';
import { ThemePalette } from 'mui.theme';

interface Props {
  data: Record<string, string>;
  field: string;
  style?: CSSProperties;
  onChange: (data: any, param: any) => void;
  enableDarkTheme: boolean;
}

export const TextFieldRenderer = ({
  data,
  field,
  onChange,
  enableDarkTheme = false,
}: Props) => {
  const [value, setValue] = useState(data[field] ?? '');

  return (
    <Box justifyContent={'center'}>
      <TextField
        disabled={!data.isActive}
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          display: 'flex',
          '& .MuiInputBase-root': {
            height: '30px',
            width: '275px',
          },
          ...(enableDarkTheme
            ? { backgroundColor: 'inherit' }
            : { WebkitBoxShadow: '0 0 0 1000px white inset' }),
          ...(enableDarkTheme && {
            border: `1px solid ${String(ThemePalette.border)}`,
          }),
          ...(enableDarkTheme && {
            input: {
              color: ThemePalette.typography.wheat,
            },
          }),
        }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onBlur={() => {
          onChange(data, value);
        }}
      />
    </Box>
  );
};
